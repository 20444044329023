import apiconnection from "../api/general";

const saveEmail = async (body) => {
  const response = await apiconnection.post(
    `/email/createTemplate`,
    body
  );
  return response.data;
};


const emailFeature = {
    saveEmail
};

export default emailFeature;
