import React, { useState, useCallback,useMemo, useEffect,useRef } from "react";
import ellipse from '../../../assets/Ellipse.png'
import discovery from '../../../assets/boxsearch.png'
import Icon_3 from '../../../assets/Icon (3).png'
import Icon_4 from '../../../assets/Icon (4).png'
import Icon_5 from '../../../assets/Icon (5).png'
import Icon_6 from '../../../assets/Icon (6).png'
import Icon_7 from '../../../assets/Icon (7).png'
import bell_1 from '../../../assets/bell (1).png'
import bell_3 from '../../../assets/bell (3).png'
import bell_4 from '../../../assets/bell (4).png'
import Icon_8 from '../../../assets/Icon (8).png'
import Icon_9 from '../../../assets/Icon (9).png'
import Icon_11 from '../../../assets/Icon@2x.png'
import edit from '../../../assets/Edit.png'
import delete_img from '../../../assets/Delete.png'
import file from '../../../assets/file.png'
import rightarrow from '../../../assets/rightarrow.png'
import liftarrow from '../../../assets/leftarrow.png'
import paypal from '../../../assets/Paypal.png'
import cheque from '../../../assets/cheque.png'
import momo from '../../../assets/momo.webp'
import cash from '../../../assets/cash.png'
import mastercard from '../../../assets/Mastercard.png'
import visa from '../../../assets/Visa.png'
import googlemap from '../../../assets/googlemap.png'
import location from '../../../assets/location.png'
import filter from '../../../assets/filter.png'
import frame from '../../../assets/Frame.png'
import xcelsz from '../../../assets/xcelsz.png'
import MainIcon from "../../../assets/main_logo.png";
import ProfileIcon from "../../../assets/profile.svg";
import LogoutIcon from "../../../assets/logout1.svg";
import search from "../../../assets/search.svg";

// import { Calendar } from "@natscale/react-calendar";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { ToastContainer, toast } from 'react-toastify';

import EditDeals from "../../../Components/Deals/EditDeals";
import {useDropzone} from 'react-dropzone';

import * as Yup from "yup";
import { useFormik,Field  } from "formik";

import axios from 'axios';
import { UploadButton } from "@bytescale/upload-widget-react";

import "./listOview.css";
import { useNavigate, Link } from "react-router-dom";

import Pagination from './pagination';
let PageSize = 10;

const options = {
    apiKey: "free", //"public_FW25brM9kSSiy5Gd5VXC2qKy8vzi", // Get API key: https://www.bytescale.com/get-started
    maxFileCount: 10,
    mimeTypes: ["image/*"],
    editor: {
      images: {
        allowResizeOnMove: true,
        crop: true,
        // cropFilePath: ,
        cropRatio: 1.778,
        // cropShape: "circ",
        preview: true,
      },
    },
  };



function ListingOview() {
    const notify = (msg) => toast(msg);
    // const [value, onChange] = useState(new Date());
    const [value, setValue] = useState(null);
    const [deal, setDeal] = useState('Occupy');
    const [loading, setLoading] = useState(false);
    // const [mediaUrl, setMediaUrl] = useState([]);
    const [mediaUrl, setMediaUrl] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [publised, setPublised] = useState(0);
    const [dealVisibility, setDealVisibility] = useState(false);

    const [filters, setFilters] = useState({ creator: '', dealType: '', action: '' });
    

    const storedUser = localStorage.getItem("ihub_user");
    const userData = JSON.parse(storedUser);

    const[defaultdata, setDefaultData] = useState([])
    const[data, setData] = useState([])
    const[listingTypesID, setListingTypesID] = useState([])

    const [isVisibleProfile, setIsVisibleProfile] = useState(false);

    const toggleDropdownProfile = () => {
        setIsVisibleProfile(!isVisibleProfile);
    };
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem("ihub_user");
        navigate("/login");
      };


    const [currentPage, setCurrentPage] = useState(1);

    const currentTableData = useMemo(() => {
      const firstPageIndex = (currentPage - 1) * PageSize;
      const lastPageIndex = firstPageIndex + PageSize;
      return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage,data]);

    
    function countLabels(data, label) {
        return data.filter(item => item.deal_type_id === label).length;
    }
    function countLabelsArchive(data) {
        return data.filter(item => item.status === '2').length;
    }

    // function dealName(label) {
    //     console.log(JSON.parse(listingTypesID.filter(item => item._id === label)) );
    //     return JSON.parse(listingTypesID.filter(item => item._id === label)) 
    // }
    
    // const cdealCount = countLabels(jsonData, 'cdeal');
    // const kdealCount = countLabels(jsonData, 'kdeal')

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  
    // const files = acceptedFiles.map(file => (
    //   <li key={file.path}>
    //     {file.path} - {file.size} bytes
    //   </li>
    // ));


    // const setImage = (url) => {
    //     if (url) {
    //       setMediaUrl(url);
    //     }
    //   };

    useEffect(()=>{
        console.log(mediaUrl);
    },[mediaUrl])
 

  const formik = useFormik({
    initialValues: {
      propertyName: "",
      propertyType: "",
      propertyCategory: "",
      propertySize: "",
      propertyPrice: "",
      propertyUse: "",
      room:"",
      beds:"",
      parkingSpace:"",
      furnishedStatus:"",
      residentialAddress:"",
      googleMapsAddress:"",
    //   propertyMediaFiles:"",
      summary:"",
      keywords:"",
    //   paymentMethod:""
      paymentMethod: [],
    },
    validationSchema: Yup.object({
    propertyName:  Yup.string()
        .required("Property Name is required")
        .label("Property Name"),
    propertyType:  Yup.string()
        .required("Property Type is required")
        .label("Property Type"),
    propertyCategory:  Yup.string()
        .required("Property Category is required")
        .label("Property Category"),
    propertySize:  Yup.string()
        .required("Property Size is required")
        .label("Property Size"),
    propertyPrice:  Yup.string()
        .required("Property Price is required")
        .label("Property Price"),
    // propertyUse: Yup.array()
    //     .label("Property Use")
    //     .max(1, "At least one input must be selected")
    //     .min(1, "At least one input must be selected")
    //     .required("Property Use is required"),
    propertyUse: Yup.string().required('You must select an option'),
    room: Yup.string()
        .required("Room is required")
        .label("Room"),
    beds: Yup.string()
        .required("Beds is required")
        .label("Beds"),
    parkingSpace: Yup.string()
        .required("Parking Space is required")
        .label("Parking Space"),
    furnishedStatus: Yup.string().required('You must select furnished status '),
    // paymentMethod: Yup.string().required('Payment Method is required '),
    paymentMethod: Yup.array().min(1,'At least one payment option must be selected'),
    // furnishedStatus: Yup.string()
    //     // .required("Furnished Status is required")
    //     .label("Furnished Status")
    //     .max(1, "At least one input must be selected")
    //     .min(1, "At least one input must be selected")
    //     .required("Furnished Status is required"),
    residentialAddress: Yup.string()
        .required("Email address is required")
        .label("Email address"),
    googleMapsAddress: Yup.string()
        .required("Google Maps Address is required")
        .label("Google Maps Address"),
    // propertyMediaFiles: Yup.string()
    //     .required("Property Media Files is required")
    //     .label("Property Media Files"),
    summary: Yup.string()
        .required("Summary is required")
        .label("Summary"),
    keywords: Yup.string()
        .required("Keywords is required")
        .label("Keywords")
    }),
    // paymentMethod: Yup.boolean()
    //     .oneOf(['credit', 'paypal','momo'], 'Payment method is required')
    //     .required('Payment method is required')
    //     .label("Payment method"),

    onSubmit: async (values, {resetForm}) => {
        
        console.log(values);
        console.log(mediaUrl);
        
        setLoading(true)
        let dealType_id = ''
        if (deal == 'Occupy') {
            dealType_id='occupy'
        }else if (deal == 'Invest') {
            dealType_id='invest'
        }else{
            dealType_id='jv'
        }

        const selectedPaymentMethods = values.paymentMethod.join(',');
        const uuid = Math.floor(Math.random() * 900) + 100;

        const formData = new FormData();
        formData.append('file', JSON.stringify(mediaUrl[0]));
         formData.append('propertyName',values.propertyName);
         formData.append('uuid',`list${uuid}`);
         formData.append('deal_type_id', dealType_id);
         formData.append('propertyType',values.propertyType);
         formData.append('propertyCategory',values.propertyCategory);
         formData.append('propertySize',values.propertySize);
         formData.append('propertyPrice',values.propertyPrice);
         formData.append('propertyUse',values.propertyUse);
         formData.append('room',values.room);
         formData.append('beds',values.beds);
         formData.append('parkingSpace',values.parkingSpace);
         formData.append('furnishedStatus',values.furnishedStatus);
         formData.append('residentialAddress',values.residentialAddress);
         formData.append('googleMapsAddress',values.googleMapsAddress);
        //  formData.append('propertyMediaFiles',values.propertyMediaFiles);
         formData.append('summary',values.summary);
         formData.append('creator',userData.email);
         formData.append('keywords',values.keywords);
         formData.append('payment_mode',selectedPaymentMethods);
         formData.append('status','0');


        try {
            const response = await axios.post(
              'https://ihubbackend2.xcelsz.com/api/listing/upload_listing',
              formData,{
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            );

            getallListiing()
            setDealVisibility(false)
            notify('Property Deal Created')
            resetForm()
          } catch (error) {
            toast.danger('Property Deal Creation Failed')
            setLoading(false)
            console.error('Error uploading file:', error);
          }
        setLoading(false)
    },
  });

  useEffect(()=>{
    setMediaUrl([])
  },[dealVisibility])

  const getallListiing = async ()=>{
    try {
        const response = await axios.get(
          ' https://ihubbackend2.xcelsz.com/api/listing/get-all-deals');
        setFilters({ creator: '', dealType: '', action: '' })
        setData(response.data)
        setDefaultData(response.data)
        setCurrentPage(1)
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }

  const [warning, setWarning] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const deleteListWarning = (id)=>{
    setWarning(true)
    setDeleteId(id)
  }

  const deleteListiing = async ()=>{
    try {
        const response = await axios.delete(
          `https://ihubbackend2.xcelsz.com/api/listing/delete/${deleteId}`);
        getallListiing()
        notify('card deleted')
        setWarning(false)
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }

  const getallListiingTypes = async ()=>{
    try {
        const response = await axios.get(
          'https://ihubbackend2.xcelsz.com/api/feature/listingTypes');
        setListingTypesID(response.data)
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }

  const publishListing = async (id)=>{
    console.log(id);
    try {
        const response = await axios.patch(`https://ihubbackend2.xcelsz.com/api/listing/archive-listing/${id}`,{
            "status":"1"
        });
        notify('Property deal status to updated')
        getallListiing()
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }

  const archiveListing = async (id)=>{
    try {
        const response = await axios.patch(`https://ihubbackend2.xcelsz.com/api/listing/archive-listing/${id}`,{
            "status":"2"
        });
        notify('Property deal status to updated')
        getallListiing()
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }
  const conceal = async (id)=>{
    try {
        const response = await axios.patch(`https://ihubbackend2.xcelsz.com/api/listing/conceal-listing/${id}`,{
            "status":"0"
        });
        notify('Property deal status to updated')
        getallListiing()
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }
  const recycle = async (id)=>{
    try {
        const response = await axios.patch(`https://ihubbackend2.xcelsz.com/api/listing/conceal-listing/${id}`,{
            "status":"3"
        });
        notify('Property deal sent to recycle')
        getallListiing()
      } catch (error) {
        console.error('Error uploading file:', error);
      }
  }


  useEffect(()=>{
    overView()
  },[defaultdata])
  useEffect(()=>{
    getallListiing()
    getallListiingTypes()
    overView()
  },[])








  const onChange = (newValue) => {
    console.log(newValue);
    
    setValue(newValue);
  };

//   setData
  const filteredData = ()=>{
    // let ii = data.filter(item => {
    //     if (!value) return true; // No date selected, show all
    //     if (Array.isArray(value)) {
    //       const [startDate, endDate] = value;
    //       return item.created_at >= startDate && item.created_at <= endDate;
    //     }
    //     return item.date === value;
    //   })


      let filterOutput = defaultdata.filter(item => {
        const createdAt = new Date(item.created_at);
  
        if (Array.isArray(value)) {
          const [startDate, endDate] = value;
          return createdAt >= new Date(startDate).setHours(0, 0, 0, 0) &&
                 createdAt <= new Date(endDate).setHours(23, 59, 59, 999);
        } else if (value) {
          const startOfDay = new Date(value).setHours(0, 0, 0, 0);
          const endOfDay = new Date(value).setHours(23, 59, 59, 999);
          return createdAt >= startOfDay && createdAt <= endOfDay;
        }
  
        return defaultdata; // No date selected, show all
      });
      setData(filterOutput)
      
  }

    useEffect(()=>{
        filteredData()
    },[value])

    const clearDates = () => {
        setValue(null);
        setData(defaultdata)
    };


    const overView = () => {
        // Assuming 'status' is a variable that holds the filter criteria
        const status = '1'; // Replace 'someStatus' with the actual status you want to filter by
    
        let filterOutput = defaultdata.filter(item => item.status === status);
        // Do something with filterOutput
        // Calculate the total number of items
        const totalItems = defaultdata.length;

        // Calculate the number of filtered items
        const filteredCount = filterOutput.length;
        setPublised(filteredCount)

        // Calculate the percentage
        const percentage = totalItems > 0 ? (filteredCount / totalItems) * 100 : 0;
        console.log(percentage);
        
        setPercentage(percentage)
    }

    const closeAllModels = ()=>{
        setDropdownVisible({})
        setDropdownArchiveVisible({})
        setDropdownLiveVisible({})
        setDropdownFilterVisible({})
    }

    const [searchKey, setSearchKey] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState({});
    const [dropdownLiveVisible, setDropdownLiveVisible] = useState({});
    const [dropdownArchiveVisible, setDropdownArchiveVisible] = useState({});
    const [dropdownFilterVisible, setDropdownFilterVisible] = useState(false);
    const dropdownRefs = useRef({});
    const dropdownRefsLive = useRef({});
    const dropdownRefsArchive = useRef({});

    const toggleDropdown = (uuid) => {
        setDropdownVisible((prevState) => ({
            ...prevState,
            [uuid]: !prevState[uuid]
        }));
    };

    const toggleLiveDropdown = (uuid) => {
        setDropdownLiveVisible((prevState) => ({
            ...prevState,
            [uuid]: !prevState[uuid]
        }));
    };

    const toggleArchiveDropdown = (uuid) => {
    setDropdownArchiveVisible((prevState) => ({
        ...prevState,
        [uuid]: !prevState[uuid]
    }));
    };

    const toggleFilterDropdown = () => {
        setDropdownFilterVisible((prev)=>!prev)
    };

    const renderliveButton = (listing) => {
        const isVisible = dropdownLiveVisible[listing.uuid];
    
        return (
            <div ref={el => dropdownRefsLive.current[listing.uuid] = el} style={{ position: 'relative', display: 'inline-block' }}>
                <button style={{color:'#000000', background:'#fac710'}} className="publish-button" onClick={() => toggleLiveDropdown(listing.uuid)}>
                    Live
                </button>
                {isVisible && (
                    <div className={`dropdown-menu show`}>
                        <button className="dropdown-item" onClick={() => archiveListing(listing._id)}>Archive Deal</button>
                        <button className="dropdown-item" onClick={()=>conceal(listing._id)}>Unpublish</button>
                    </div>
                )}
            </div>
        );
    };
    
    const renderPublishButton = (listing) => {
        const isVisible = dropdownVisible[listing.uuid];
    
        return (
            <div ref={el => dropdownRefs.current[listing.uuid] = el} style={{ position: 'relative', display: 'inline-block' }}>
                
                <button className="publish-button" style={{color: 'rgb(255, 255, 255)', background: 'black'}} onClick={() => toggleDropdown(listing.uuid)}>Publish</button>

                {isVisible && (
                    <div className={`dropdown-menu show`}>
                        <button className="dropdown-item" onClick={() => publishListing(listing._id)}>Publish Now</button>
                        {/* <button className="dropdown-item">Review before Publish</button> */}
                    </div>
                )}
            </div>
        );
    };

    const renderArchieveButton = (listing) => {
        const isVisible = dropdownArchiveVisible[listing.uuid];
    
        return (
            <div ref={el => dropdownRefsArchive.current[listing.uuid] = el} style={{ position: 'relative', display: 'inline-block' }}>
                <button style={{ background:'grey', color:'#000000' }} className="publish-button" onClick={() => toggleArchiveDropdown(listing.uuid)}>
                Archive
                </button>
                {isVisible && (
                    <div className={`dropdown-menu show`}>
                        <button className="dropdown-item" onClick={() => publishListing(listing._id)}>Publish Now</button>
                        {/* <button className="dropdown-item">Review before Publish</button> */}
                    </div>
                )}
            </div>
        );
    };
    const renderRecycleButton = (listing) => {
        const isVisible = dropdownArchiveVisible[listing.uuid];
    
        return (
            <div ref={el => dropdownRefsArchive.current[listing.uuid] = el} style={{ position: 'relative', display: 'inline-block' }}>
                <button style={{ background:'red', color:'#000000' }} className="publish-button" onClick={() => toggleArchiveDropdown(listing.uuid)}>
                Deleted
                </button>
                {isVisible && (
                    <div className={`dropdown-menu show`}>
                        <button className="dropdown-item" onClick={() => conceal(listing._id)}>Restore</button>
                        <button className="dropdown-item" onClick={() => deleteListWarning(listing._id)}>Permanently Delete</button>
                        {/* <button className="dropdown-item">Review before Publish</button> */}
                    </div>
                )}
            </div>
        );
    };


    const renderfilterButton = () => {
    
        return (
            <>
            <div className={`layer ${dropdownFilterVisible ? 'show':''}`}  onClick={() => toggleFilterDropdown()} style={{ position:'fixed', top:'0', bottom:'0', left:'0', background:'transparent', right:'0', zIndex:'999'}}>
               
            </div>
            <div style={{ position: 'relative', display: 'inline-block', zIndex:'1000' }}>
                <div onClick={() => toggleFilterDropdown()} style={{ display:'flex', alignItems:'center', justifyContent:'space-between', padding:'.3rem 1rem', borderRadius:'.4rem', background:'#f9f9f9f9', border:'1px solid #000000', fontSize:'1rem', minWidth:50, cursor:'pointer' }}>
                    <span style={{ marginRight:'1rem', cursor:'pointer', fontFamily:'sans-serif'}}>+</span>
                    <p>Filter</p>
                </div>
                    <div className={`dropdown-menu ${dropdownFilterVisible ? 'show':''}`} style={{flexDirection:'column', left:'-2rem', width:'auto', gap:'.5rem', border:'none', borderRadius:0,marginTop:'1rem' }}>
                        <select onChange={(e) => handleFilterChange('creator', e.target.value)}>
                            <option value="">Select Creator</option>
                            {/* {defaultdata.map((value, index)=>{ */}
                            {Object.entries(defaultdata).map(([value,index]) => {
                                return(
                                    <option key={index} value={defaultdata[value].created}>{defaultdata[value].created}</option>
                                )

                            })}
                        </select>
                        <select onChange={(e) => handleFilterChange('dealType', e.target.value)}>
                            <option value="">Select Deal Type</option>
                            <option value="occupy">Occupy Deals</option>
                            <option value="invest">Invest Deals</option>
                            <option value="jv">JV Deals</option>
                        </select>
                        <select onChange={(e) => handleFilterChange('action', e.target.value)}>
                            <option value="">Select Action</option>
                             <option value={'1'}>Published</option>
                             <option value={'2'}>Archieved</option>
                             <option value={'0'}>Unpublished</option>
                             <option value={'3'}>Deleted</option>
                        </select>
                    </div>
            </div>
            
            
            </>
        );
    };



    const [keywordHistory, setKeywordHistory] = useState([]);


    const handleSearch = (keyword) => {
        // const newSearchKey = event.target.value;
        // const newSearchKey = searchKey;
        const newSearchKey = keyword;
        setSearchKey(newSearchKey);

        if (newSearchKey && !keywordHistory.includes(newSearchKey)) {
        setKeywordHistory([newSearchKey, ...keywordHistory]);
        }
        filteredDataByKeyboard()
    };

    const filteredDataByKeyboard = ()=>{
        const newdate = defaultdata.filter((item) =>
        Object.values(item).some((value) => value.toString().toLowerCase().includes(searchKey.toLowerCase()))
        // Object.values(item).some((value) => value.keywords.toLowerCase() == searchKey.toLowerCase())
        
        )
    
    setData(newdate)
    }


    const searchKeyword = (val)=>{
        console.log(val);
        
        setSearchKey(val);
        handleSearch(val)
    }


    // useEffect(()=>{
    //     console.log('searchKey');
        
    //     if (searchKey === '') {
    //         setData(defaultdata)
    //     }
    // },[searchKey,defaultdata])


    const [showEdit, setShowEdit] = useState(false);
    const [editData, setEditData] = useState([]);
    const handleEdit = (ele)=>{
        setEditData(ele)
        console.log(ele);
        setShowEdit(true)

        
    }


    const RemoveImage = (ele)=>{
        const updatedArray = mediaUrl[0].filter(element => element !== ele);
        setMediaUrl(updatedArray);
    }

    const RemoveFilterKeyword = (ele)=>{
        const updatedArray = keywordHistory.filter(element => element !== ele);
        setKeywordHistory(updatedArray);
    }

    // sorting in desc and asc 
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    const sortData = (key) => {
      let direction = 'ascending';
      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
  
      const sortedData = [...data].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
  
      setSortConfig({ key, direction });
      setData(sortedData);
    };

    

    const applyFilters = (datefilter) => {
        let data = defaultdata;

        console.log(defaultdata);
        // console.log(filters);
        
        

        if (datefilter.creator) {
        data = data.filter(item => item.created === datefilter.creator);
        }
        if (datefilter.dealType) {
            console.log(datefilter.dealType);
            
        data = data.filter(item => item.deal_type_id === datefilter.dealType);
        }
        if (datefilter.action) {
            console.log(datefilter.action);
            
            data = data.filter(item => item.status === datefilter.action);
        }

        setData(data);
        // console.log(data);
    };

    const handleFilterChange = (filterName, value) => {
        // console.log(value);
        // console.log(filterName);
        // console.log({ ...filters, [filterName]: value });
        toggleFilterDropdown()
        
        setFilters({ ...filters, [filterName]: value });
        applyFilters({ ...filters, [filterName]: value });
    };

    // const removeFilter = (filterName) => {
    //     setFilters({ ...filters, [filterName]: '' });
    //     applyFilters();
    // };





    
    return ( 
        <>
        <ToastContainer />
        {dealVisibility && 
        <div style={{ position:'fixed', height:'100vh', width:'100%', background:'rgba(0,0,0,.4)',zIndex:9999, top:0, left:0, right:0, button:0 }}>
            <div style={{ background:'white', width:'50%', height:'100%', margin:'2rem auto', borderRadius:'1rem', padding:'2rem', overflowY:'scroll' }}>
                <form style={{ marginBottom:'2rem' }} onSubmit={formik.handleSubmit}>
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                        <h2>{`Add New ${deal} Deal`}</h2>
                        <div onClick={(prev)=>{setDealVisibility(false)}} style={{ background:'#FBF6E4', width:40, height:40, borderRadius:'50%',display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer' }}>
                            <h2 style={{ cursor:'pointer' }}>x</h2>
                        </div>
                    </div>
                    <span style={{ padding:'3rem 0' }}>General Information</span>
                    
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Name</label>
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyName} type="text" name="propertyName" id="propertyName" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Enter property name" />
                        {formik.touched.propertyName && formik.errors.propertyName ? (
                            <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyName}</p>
                        ) : null}
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'row', display:'flex', gap:'1rem' }}>
                        <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Type</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyType} type="text" name="propertyType" id="propertyType" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Select  property type. eg;Apartment" />
                            {formik.touched.propertyType && formik.errors.propertyType ? (
                            <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyType}</p>
                        ) : null}
                        </div>
                        <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Category</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyCategory} type="text" name="propertyCategory" id="propertyCategory" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Select category. eg;B2S" />
                            {formik.touched.propertyCategory && formik.errors.propertyCategory ? (
                            <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyCategory}</p>
                        ) : null}
                        </div>
                    </div>
                    
                    <div style={{ marginTop:'10px', flexDirection:'row', display:'flex', gap:'3rem' }}>
                        <div style={{ flexDirection:'row', display:'flex', width:'50%',gap:'1rem' }}>
                            <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Size ( m² )</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertySize} type="number" name="propertySize" id="propertySize" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                {formik.touched.propertySize && formik.errors.propertySize ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertySize}</p>
                                ) : null}
                            </div>
                            <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Price ( USD )</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyPrice} type="number" name="propertyPrice" id="propertyPrice" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                            {formik.touched.propertyPrice && formik.errors.propertyPrice ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyPrice}</p>
                                ) : null}
                            </div>
                           
                        </div>
                        <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Use</label>
                            <div style={{ display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                                <div style={{ display:'flex', alignItems:'center' }}>
                                    <input type="radio" name="propertyUse" id="" value='Commercial' style={{ margin:'1rem' }} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <p>Commercial</p>
                                </div>
                                <div style={{ display:'flex', alignItems:'center' }}>
                                    <input type="radio" name="propertyUse" id="" value='Residential' style={{ margin:'1rem' }} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    <p>Residential</p>
                                </div>



                            </div>
                            {formik.touched.propertyUse && formik.errors.propertyUse ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyUse}</p>
                                ) : null}
                        </div>
                    </div>

                    <div style={{ marginTop:'10px', flexDirection:'row', display:'flex', gap:'3rem' }}>
                        <div style={{ flexDirection:'row', display:'flex', width:'50%',gap:'1rem' }}>
                            <div style={{ flexDirection:'column', display:'flex', width:'30%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Bed rooms</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.room} type="number" name="room" id="room" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                {formik.touched.room && formik.errors.room ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.room}</p>
                                ) : null}
                            </div>
                            <div style={{ flexDirection:'column', display:'flex', width:'30%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Bath</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.beds} type="number" name="beds" id="beds" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                {formik.touched.beds && formik.errors.beds ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.beds}</p>
                                ) : null}
                            </div>
                            <div style={{ flexDirection:'column', display:'flex', width:'30%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Parking Space</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.parkingSpace} type="number" name="parkingSpace" id="parkingSpace" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                            {formik.touched.parkingSpace && formik.errors.parkingSpace ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.parkingSpace}</p>
                                ) : null}
                            </div>
                           
                        </div>
                        <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Furnished Status</label>
                            <div style={{ display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                                <div style={{ display:'flex', alignItems:'center' }}>
                                    <input type="radio" name="furnishedStatus" value='yes' id="furnishedStatusYes" style={{ margin:'1rem' }}  onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    <p>Yes</p>
                                </div>
                                <div style={{ display:'flex', alignItems:'center' }}>
                                    <input type="radio" name="furnishedStatus" value='no' id="furnishedStatusNo" style={{ margin:'1rem' }} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                    <p>No</p>
                                </div>

                            </div>
                            {formik.touched.furnishedStatus && formik.errors.furnishedStatus ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.furnishedStatus}</p>
                                ) : null}
                        </div>
                    </div>

                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Residential Address</label>
                        <div style={{ position:'relative', width:'100%' }}>
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.residentialAddress} type="text" name="residentialAddress" id="residentialAddress" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', width:'100%' }} placeholder="Enter residential address" />
                            <img src={location} width={20} height={20} alt="" style={{ position:'absolute',right:'1rem', top:'2rem' }}/>
                        </div>
                        {formik.touched.residentialAddress && formik.errors.residentialAddress ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.residentialAddress}</p>
                            ) : null}
                      
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Google Maps Address</label>
                        <div style={{ position:'relative', width:'100%' }}>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.googleMapsAddress} type="text" name="googleMapsAddress" id="googleMapsAddress" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', width:'100%' }} placeholder="Enter google maps address" />
                            <img src={googlemap} width={25} height={25} alt="" style={{ position:'absolute',right:'1rem', top:'1.5rem' }}/>
                        </div>
                        {formik.touched.googleMapsAddress && formik.errors.googleMapsAddress ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.googleMapsAddress}</p>
                            ) : null}
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Media Files</label>
                        <section className="container">
                        <UploadButton
                            options={options}
                            onComplete={(files) =>{
                                console.log(files);
                                
                                // const url = files.map((x) => x.fileUrl).join("\n")
                                const urls = files.map((file) => file.fileUrl);
                                if (urls.length > 0) {
                                    setMediaUrl((prev)=>([...prev,urls]))
                                }

                            }
                            }
                        >
                            {({ onClick }) => (
                            // <li title="add image">
                            //     <img src={edit} onClick={onClick} alt="gallery" />
                            // </li>
                            <div onClick={onClick}>
                            {/* <div onClick={onClick} {...getRootProps({className: 'dropzone'})}> */}
                                {/* <input {...getInputProps()}  /> */}
                                <div style={{ padding:'2rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'center' }}>
                                    <img width={70} height={25} src={frame} alt="" style={{ margin:'1rem' }} />
                                    <p ><span style={{ color:'#FAC710' }}>Click here </span>to upload or drop files here</p>
                                </div>
                            </div>
                
                    )}
                        </UploadButton>
                        {/* <div onClick={onClick} {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()}  />
                            <div style={{ padding:'2rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'center' }}>
                                <img width={70} height={25} src={frame} alt="" style={{ margin:'1rem' }} />
                                <p ><span style={{ color:'#FAC710' }}>Click here </span>to upload or drop files here</p>
                            </div>
                        </div> */}
                            
                            {mediaUrl.length > 0 && <aside>
                                <h4>Files</h4>
                                <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start',flexWrap:'wrap', gap:'1rem'}}>
                                    {mediaUrl[0].map((val,index)=>{
                                        return(
                                        <ul style={{position:'relative', padding:'0rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', boxShadow:'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', fontSize:'1rem', textAlign:'left', display:'flex', alignItems:'center', width:200 }}><img width={'100%'} style={{ borderRadius:'.5rem' }} height={100} src={val} alt="" /> <span onClick={()=>RemoveImage(val)} style={{ position:'absolute', right:'.4rem', fontSize:'1.5rem', fontWeight:'bold', zIndex:99, color:'red',top:'0rem', cursor:'pointer' }}>x</span></ul>
                                        )
                                    })}
                                </div>
                                
                                
                                {/* <ul style={{position:'relative', padding:'.5rem 1rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'left', display:'flex', alignItems:'center' }}><img width={25} height={25} src={file} alt="" />{files} <span style={{ position:'absolute', right:'1rem', fontSize:'1rem', fontWeight:'bold' }}>x</span></ul> */}
                            </aside>}
                            
                            </section>
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Deal Summary</label>
                        <p style={{ fontSize:'12px' }}>A short summary listing the key features and amenities of the deal</p>
                        <textarea onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.summary} type="text" name="summary" id="summary" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} />
                        {formik.touched.summary && formik.errors.summary ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.summary}</p>
                            ) : null}
                    </div>
                    <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Keywords</label>
                        <div style={{ flexDirection:'row', display:'flex', gap:'1rem' }}>
                        {keywords.map(()=>{
                            <p style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:100 }}>Eco-Friendly <span style={{ marginLeft:'1rem' }}>X</span></p>
                        })}
                
                        {/* <p style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:100 }}>Luxury Home <span style={{ marginLeft:'1rem' }}>X</span></p> */}
                        </div>
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.keywords} type="text" name="keywords" id="keywords" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Enter or Select keywords. eg; verified , affordable, unregistered, quality assured" />
                        {formik.touched.keywords && formik.errors.keywords ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.keywords}</p>
                            ) : null}
                    </div>

                    <label htmlFor="">Payment Method Options</label>
                    <div style={{ display:'flex', alignItems:'center' }}>
    <input 
        onChange={formik.handleChange} 
        onBlur={formik.handleBlur} 
        type="checkbox" 
        name="paymentMethod" 
        value="Card" 
        id="" 
        style={{ marginRight:'1rem', display:'flex' }} 
    />
    <p>Credit card or Debit card</p>
    <img width={70} height={25} src={mastercard} alt="" style={{ margin:'1rem' }} />
    <img width={70} height={25} src={visa} alt="" style={{ margin:'1rem' }} />
                    </div>

                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                type="checkbox" 
                                name="paymentMethod" 
                                value="PayPal" 
                                id="" 
                                style={{ marginRight:'1rem', display:'flex' }} 
                            />
                            <p>PayPal</p>
                            <img width={70} height={25} src={paypal} alt="" style={{ margin:'1rem' }} />
                        </div>
                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                type="checkbox" 
                                name="cheque" 
                                value="PayPal" 
                                id="" 
                                style={{ marginRight:'1rem', display:'flex' }} 
                            />
                            <p>Cheque</p>
                            <img width={50} height={35} src={cheque} alt="" style={{ margin:'1rem' }} />
                        </div>
                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                type="checkbox" 
                                name="cash" 
                                value="PayPal" 
                                id="" 
                                style={{ marginRight:'1rem', display:'flex' }} 
                            />
                            <p>Cash Deposit</p>
                            <img width={50} height={35} src={cash} alt="" style={{ margin:'1rem' }} />
                        </div>
                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                type="checkbox" 
                                name="momo" 
                                value="PayPal" 
                                id="" 
                                style={{ marginRight:'1rem', display:'flex' }} 
                            />
                            <p>Mobile Money</p>
                            <img width={70} height={35} src={momo} alt="" style={{ margin:'1rem' }} />
                        </div>

                    {formik.touched.paymentMethod && formik.errors.paymentMethod ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.paymentMethod}</p>
                            ) : null}

                    <div className="forms-btn" style={{ display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between' }}>
                        <button style={{ cursor:'pointer' }} type="button" onClick={(prev)=>{setDealVisibility(false)}} className="cancel">Cancel</button>
                        <button style={{ cursor:'pointer' }} type="submit" className="save">Save</button>
                        {/* {!loading?<button type="submit" className="save">Save</button>:<button disabled type="submit" className="save">Loading ...</button>} */}
                        
                    </div>
                </form>
            </div>
        </div>
        }

        {showEdit && <EditDeals data={editData} closeModel={setShowEdit} alert={notify} updatelist={getallListiing}/>}
        {warning && 
        <div style={{ position:'fixed', height:'100vh', width:'100%', background:'rgba(0,0,0,.4)',zIndex:9999, top:0, left:0, right:0, button:0, display:'flex', alignItems:'center', justifyContent:'center' }}>
            <div style={{ background:'white', width:'30%', height:'200px',borderRadius:'1rem', padding:'1rem' }}>
                    <h1>Delete Deal ?</h1>
                    <p style={{ fontSize:'1rem', textAlign:'center' }}>Are you sure you will like to delete deal?
                        Deleting deal will clear all info of deal from our database !!
                    </p>
                    <p style={{ fontSize:'1rem', textAlign:'center' }}>Click on Delete to contiune</p>
                    <div style={{ width:'100%',marginTop:'1rem', display:'flex', alignItems:'center', justifyContent:'space-between', padding:'0 2rem' }}>
                    <button className="publish-button" onClick={()=>setWarning(false)}>Cancel</button>
                    <button style={{ background:'grey'}} onClick={deleteListiing} className="publish-button">Delete</button>
                    </div>
            </div>
        </div>
        }
        
        <div style={{maxWidth:'90%', margin:'0 auto', position:'relative'}} >
        
        <div className="header">
            <div className="logo">
            <Link to={'/'} style={{ width:'100%', textDecoration:'none', color:'#000000' }}>
                <img src={MainIcon} alt="logo" width={45} height={45} style={{ borderRadius:'50%' }} />
            
            </Link>
            </div>
            <div className="right-side-header">
                <div className="icons" style={{marginRight:'3rem'}}>
                <img style={{margin:'0 1rem'}} width={20} height={20} src={bell_1} alt="" />
                <img style={{margin:'0 1rem'}} width={20} height={20} src={bell_4} alt="" />
                   
                </div>
                <div className="icons" style={{ position: "relative" }} onClick={toggleDropdownProfile}>
                    <div
                    className={`dropdown-profile ${isVisibleProfile ? "visible" : "hidden"
                    }`}
                >
                    <div className="dropdown-item">
                    <span>Account</span>
                    <img src={ProfileIcon} width={20} height={20} alt="profile" />
                    </div>
                    <div className="dropdown-item" onClick={logout}>
                    <span>Log out</span>
                    <img src={LogoutIcon} width={20} height={20} alt="logout" />
                    </div>
                </div>
                    <img width={40} height={40} src={ellipse} alt="" />
                </div>
            </div>
        </div>
        <div className="work-space" >
            <h2>Work Space</h2>
        </div>
        <div className="main-overview" >
            <div className="left-menu" >
                <ul>
                    <Link to={'/workspace'} style={{ width:'100%', textDecoration:'none', color:'#000000' }}>
                        <li ><img width={20} height={20} src={Icon_4} className="icon" alt=""/>Work Space Overview </li>
                    </Link>
                    {/* <li><img width={20} height={20} src={Icon_6} className="icon" alt=""/>Work Space Overview</li> */}
                    <li><img width={20} height={20} src={Icon_9} className="icon" alt=""/>Sprint Board</li>
                    <li><img width={20} height={20} src={bell_3} className="icon" alt=""/>Team Backlog</li>
                    <li><img width={20} height={20} src={file} className="icon" alt=""/>Compliance</li>
                    <li><img width={20} height={20} src={file} className="icon" alt=""/>User Experience (UX)</li>
                    <li><img width={20} height={20} src={Icon_7} className="icon" alt=""/>Leads</li>
                    <li className='active'><img width={20} height={20} src={Icon_4} className="icon" alt=""/>Listing <span className='total-tag'>{data.length}</span></li>
                    <li><img width={20} height={20} src={Icon_3} className="icon" alt=""/>Ouality</li>
                    <li><img width={20} height={20} src={discovery} className="icon" alt=""/>Discovery</li>
                    <li><img width={20} height={20} src={Icon_11} className="icon" alt=""/>Maintenance</li>
                    <li><img width={20} height={20} src={file} className="icon" alt=""/>Governance</li>
                    <li><img width={20} height={20} src={Icon_8} className="icon" alt=""/>Security</li>
                </ul>
            </div>
            <div className="right-blog">
                <div className='listing-overview'>
                <div className="top-section">
                    <h1>Listing Overview</h1>
                    <div className="check">
                    <span style={{marginBottom:'2rem'}}>Health Check</span>
                    <div style={{ display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <h2>{`${publised} Published`}</h2>
                    <span>{`${defaultdata.length - publised} In Progess`} </span>
                    
                    </div>
                    </div>
                    <div className='' style={{margin:'1rem 2rem'}}>
                    <div className='process-bar'><div className='process' style={{ width:`${percentage}%` }}></div></div>
                    </div>
                    
                </div>
                <div className="down-section" style={{ padding:'2rem' }}>
                    <h3>Listing Summary</h3>
                    <div className='tags-container'>
                        <div className='tags'>
                            <span>total</span>
                            <p className='number'>{defaultdata.length}</p>
                        </div>
                        <div className='tags'>
                            <span>Occupy Deals</span>
                            <p className='number'>{countLabels(defaultdata, 'occupy')}</p>
                        </div>
                        <div className='tags'>
                            <span>invest Deals</span>
                            <p className='number'>{countLabels(defaultdata, 'invest')}</p>
                        </div>
                        <div className='tags'>
                        <span>Joint Venture (JV) Deals  </span>
                            <p className='number'>{countLabels(defaultdata, 'jv')}</p>
                        </div>
                        {/* <div className='tags'>
                            <span>Deal Of the Month</span>
                            <p className='number'>9</p>
                        </div> */}
                        <div className='tags'>
                            <span>archived</span>
                            <p className='number'>{countLabelsArchive(defaultdata)}</p>
                        </div>
                        {/* <div className='tags'>
                            <span>deleted</span>
                            <p className='number'>0</p>
                        </div> */}
                        {/* <div className='tags'>
                            <span>draft</span>
                            <p className='number'>5</p>
                        </div> */}
                    </div>
                </div>

                </div>
                <div className='create-deal-listing'>
                <div className="top-section">
                    <h1>Create Deals Listings</h1>
                    <div className='deals-tags'>
                        <h3 onClick={()=>{setDeal('Occupy')}} className={deal === 'Occupy'?`active`:``}>Occupy Deals</h3>
                        <h3 onClick={()=>{setDeal('Invest')}} className={deal === 'Invest'?`active`:``}>Invest Deals</h3>
                        <h3 onClick={()=>{setDeal('JV')}} className={deal === 'JV'?`active`:``}>JV Deals</h3>
                    </div>
                    <div className='cta-deal'>
                        <div className='wrapper'>
                            {deal === 'Occupy' && <p>Create New Property Occupancy Deal</p>}
                            {deal === 'Invest' && <p>Create New Property Investment Deal</p>}
                            {deal === 'JV' && <p>Create New Property JV Deal</p>}
                        
                        <button onClick={(prev)=>{setDealVisibility(true)}}>Create Deal</button>
                        </div>
                    </div>
                    
                </div>
                <div className="down-section">
                    <h3>Solution Platforms</h3>
                    <div className='platfroms'>
                    <img src={xcelsz} width={50} height={50} alt="" style={{ borderRadius:'50%', margin:'1rem', border:'2px solid #fac710' }}/>
                    </div>
                </div>

                </div>
                <div className='all-deal-listing'>
                <div className="top-section">
                    <h1>All Deals Listings</h1>
                </div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <div style={{padding:'1rem'}}>
                        <Calendar
                            onChange={onChange}
                            value={value}
                            selectRange={true}
                            className='calendar'
                        />
                         {/* <div className="forms-btn" style={{ display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between' }}> */}
                            <button style={{ cursor:'pointer' }} onClick={clearDates} >
                                Clear Dates
                            </button>

                         {/* </div> */}
                    </div>
                    <div style={{padding:'1rem'}}>
                        <div style={{ position:'relative' }}>
                            <input value={searchKey} onChange={(event)=>setSearchKey(event.target.value)} type="text" name="" id="" style={{ padding:'.6rem 1rem', borderRadius:'.5rem', margin:'1rem 0', border:'1px solid #000000', fontSize:'1rem', width:400 }} placeholder="Search" />
                            <img onClick={()=>handleSearch(searchKey)} style={{ position:'absolute', right:'1rem', top:'1.5rem', cursor:'pointer' }} width={20} src={search} alt="search" />
                        </div>
                        <div style={{ flexDirection:'row', display:'flex', gap:'1rem', flexWrap:'wrap', alignItems:'center',width:400 }}>
                            {/* <img src={filter} width={25} height={25} alt=""/> */}
                            <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between', padding:'.3rem 1rem', borderRadius:'.4rem', background:'#FBF6E4', border:'1px solid #000000', fontSize:'1rem', minWidth:50,cursor:'pointer' }}>
                            <p onClick={()=>{searchKeyword('')}}>All</p>
                            {/* <span style={{ marginLeft:'1rem', cursor:'pointer', fontFamily:'sans-serif'}}>X</span> */}
                            </div>

                            {keywordHistory.map((keyword, index) => (
                                <div key={index} style={{ display:'flex', alignItems:'center', justifyContent:'space-between', padding:'.3rem 1rem', borderRadius:'.4rem', background:'#FBF6E4', border:'1px solid #000000', fontSize:'1rem', minWidth:50}}>
                                    <p style={{ cursor:'pointer' }} onClick={()=>searchKeyword(keyword)}>{keyword}</p>
                                    <span onClick={()=>{RemoveFilterKeyword(keyword)}} style={{ marginLeft:'1rem', cursor:'pointer', fontFamily:'sans-serif'}}>X</span>
                                </div>
                            ))}

                            {/* <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between', padding:'.3rem 1rem', borderRadius:'.4rem', background:'#f9f9f9f9', border:'1px solid #000000', fontSize:'1rem', minWidth:50, cursor:'pointer' }}>
                            <span style={{ marginRight:'1rem', cursor:'pointer', fontFamily:'sans-serif'}}>+</span>
                            <p>Filter</p>
                            </div> */}
                            {renderfilterButton()}
                            
                           
                        </div>
                    </div>
                </div>
                <div style={{width:'100%', padding:'1rem'}}>
                    <table style={{width:'100%', borderRadius:'1rem',background:'#fbf6e4'}}>
                        {/* <tr >
                            <th style={{textAlign:'center',padding:'.5rem'}}><input type='radio'/></th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>ID</th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>Creator</th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>Listing Type</th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>Listing Name</th>
                            <th style={{textAlign:'center',padding:'.5rem'}}> Action</th>
                            
                        </tr> */}
                         <thead>
                            <tr>
                            <th style={{textAlign:'center',padding:'.5rem'}}><input type='radio' disabled /></th>
                            <th style={{textAlign:'center',padding:'.5rem', cursor: 'pointer'}} onClick={() => sortData('uuid')}>
                                ID {sortConfig.key === 'uuid' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th style={{textAlign:'center',padding:'.5rem', cursor: 'pointer'}} onClick={() => sortData('creator')}>
                                Creator {sortConfig.key === 'creator' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th style={{textAlign:'center',padding:'.5rem', cursor: 'pointer'}} onClick={() => sortData('deal_type_id')}>
                                Listing Type {sortConfig.key === 'deal_type_id' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th style={{textAlign:'center',padding:'.5rem', cursor: 'pointer'}} onClick={() => sortData('propertyName')}>
                                Listing Name {sortConfig.key === 'propertyName' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>Action</th>
                            </tr>
                        </thead>
                        {currentTableData.length > 0 && 
                        <>
                             {currentTableData.map((val, key) => {


const shouldRenderRow =
(filters.action === '3' && val.status === '3') || // Show "recycle" when filter is "delete"
(filters.action === '2' && val.status === '2') || // Show "archive" when filter is "archive"
(val.status !== '3' && val.status !== '2');       // Hide "recycle" and "archive" when no filter

  if (shouldRenderRow) {
    return (
        <tr key={key} style={{ background: '#ffffff' }}>
          <td style={{ textAlign: 'center', padding: '.5rem' }}>
            <input type='radio' />
          </td>
          <td style={{ textAlign: 'center', padding: '.5rem' }}>{val.uuid}</td>
          <td style={{ textAlign: 'center', padding: '.5rem' }}>{val.created}</td>
          <td style={{ textAlign: 'center', padding: '.5rem' }}>{val.deal_type_id}</td>
          <td style={{ textAlign: 'center', padding: '.5rem' }}>{val.propertyName}</td>
          <td
            style={{
              textAlign: 'center',
              padding: '.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
          {val.status === "1"&& <>{renderliveButton(val)}</>}
          {val.status === "0"&& <>{renderPublishButton(val)}</>}
          {val.status === "2"&& <>{renderArchieveButton(val)}</>}
          {val.status === "3"&& <>{renderRecycleButton(val)}</>}
  
            <img
              style={{ margin: '0 1rem', cursor: 'pointer' }}
              width={20}
              height={20}
              src={edit}
              alt=''
              onClick={() => {
                handleEdit(val);
              }}
            />
  
            {val.status !== '3' && (
              <img
                onClick={() => {
                  recycle(val._id);
                }}
                style={{ margin: '0 1rem', cursor: 'pointer' }}
                width={20}
                height={20}
                src={delete_img}
                alt=''
              />
            )}
          </td>
        </tr>
      );
   
  }
                                // if (val.status !== '2' && filters.action !=='2' ) {
                                //     return (
                                //         <tr key={key} style={{background:'#fffff'}}>
        <td style={{textAlign:'center',padding:'.5rem'}}><input type='radio'/></td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.uuid}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.created}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.deal_type_id}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.propertyName}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                            
                                //             {val.status === "1"&& <>{renderliveButton(val)}</>}
                                //             {val.status === "0"&& <>{renderPublishButton(val)}</>}
                                //             {val.status === "2"&& <>{renderArchieveButton(val)}</>}
                                //             {val.status === "3"&& <>{renderRecycleButton(val)}</>}
        
                                            
                                               
                                //                 <img style={{margin:'0 1rem',cursor:'pointer'}} width={20} height={20} src={edit} alt="" onClick={()=>{handleEdit(val)}} />
                                //                 {/* <img onClick={()=>{deleteListWarning(val._id)}} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" /> */}
                                //                 {val.status !== "3" &&  <img onClick={()=>{recycle(val._id)}} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" />}
                                //             </td>
                                //         </tr>
                                //     )
                                // }else if (val.status === '2' && filters.action ==='2' ) {
                                //     return (
                                //         <tr key={key} style={{background:'#fffff'}}>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}><input type='radio'/></td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.uuid}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.created}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.deal_type_id}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.propertyName}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                            
                                //             {val.status === "1"&& <>{renderliveButton(val)}</>}
                                //             {val.status === "0"&& <>{renderPublishButton(val)}</>}
                                //             {val.status === "2"&& <>{renderArchieveButton(val)}</>}
                                //             {val.status === "3"&& <>{renderRecycleButton(val)}</>}
        
                                            
                                               
                                //                 <img style={{margin:'0 1rem',cursor:'pointer'}} width={20} height={20} src={edit} alt="" onClick={()=>{handleEdit(val)}} />
                                //                 {/* <img onClick={()=>{deleteListWarning(val._id)}} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" /> */}
                                //                 {val.status !== "3" &&  <img onClick={()=>{recycle(val._id)}} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" />}
                                               
        
                                //             </td>
                                //         </tr>
                                //     )
                                // }else if (val.status === '3' && filters.action ==='3') {
                                //     return (
                                //         <tr key={key} style={{background:'#fffff'}}>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}><input type='radio'/></td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.uuid}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.created}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.deal_type_id}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.propertyName}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                            
                                //             {val.status === "1"&& <>{renderliveButton(val)}</>}
                                //             {val.status === "0"&& <>{renderPublishButton(val)}</>}
                                //             {val.status === "2"&& <>{renderArchieveButton(val)}</>}
                                //             {val.status === "3"&& <>{renderRecycleButton(val)}</>}
        
                                            
                                               
                                //                 <img style={{margin:'0 1rem',cursor:'pointer'}} width={20} height={20} src={edit} alt="" onClick={()=>{handleEdit(val)}} />
                                //                 {/* <img onClick={()=>{deleteListWarning(val._id)}} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" /> */}
                                //                 {val.status !== "3" &&  <img onClick={()=>{recycle(val._id)}} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" />}
                                               
        
                                //             </td>
                                //         </tr>
                                //     )
                                // }else if (val.status !== '3' && filters.action !=='3') {
                                //     return (
                                //         <tr key={key} style={{background:'#fffff'}}>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}><input type='radio'/></td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.uuid}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.created}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.deal_type_id}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem'}}>{val.propertyName}</td>
                                //             <td style={{textAlign:'center',padding:'.5rem', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                            
                                //             {val.status === "1"&& <>{renderliveButton(val)}</>}
                                //             {val.status === "0"&& <>{renderPublishButton(val)}</>}
                                //             {val.status === "2"&& <>{renderArchieveButton(val)}</>}
                                //             {val.status === "3"&& <>{renderRecycleButton(val)}</>}
        
                                            
                                               
                                //                 <img style={{margin:'0 1rem',cursor:'pointer'}} width={20} height={20} src={edit} alt="" onClick={()=>{handleEdit(val)}} />
                                //                 {/* <img onClick={()=>{deleteListWarning(val._id)}} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" /> */}
                                //                 {val.status !== "3" &&  <img onClick={()=>{recycle(val._id)}} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" />}
                                               
        
                                //             </td>
                                //         </tr>
                                //     )
                                // }
                            
                        })}
                        
                        </>
                        
                        
                        }
                        
                    </table>

                    {/* <div>
            
                        {filters.creator && <span>Creator: {filters.creator} <button onClick={() => removeFilter('creator')}>x</button></span>}
                        {filters.dealType && <span>Deal Type: {filters.dealType} <button onClick={() => removeFilter('dealType')}>x</button></span>}
                        {filters.action && <span>Action: {filters.action} <button onClick={() => removeFilter('action')}>x</button></span>}
                    </div>
                    <table style={{width:'100%', borderRadius:'1rem',background:'#fbf6e4'}}>
                        <thead>
                            <tr>
                            <th style={{textAlign:'center',padding:'.5rem'}}><input type='radio' disabled /></th>
                            <th style={{textAlign:'center',padding:'.5rem', cursor: 'pointer'}} onClick={() => sortData('uuid')}>
                                ID {sortConfig.key === 'uuid' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th style={{textAlign:'center',padding:'.5rem', cursor: 'pointer'}} onClick={() => sortData('creator')}>
                                Creator {sortConfig.key === 'creator' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th style={{textAlign:'center',padding:'.5rem', cursor: 'pointer'}} onClick={() => sortData('deal_type_id')}>
                                Listing Type {sortConfig.key === 'deal_type_id' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th style={{textAlign:'center',padding:'.5rem', cursor: 'pointer'}} onClick={() => sortData('propertyName')}>
                                Listing Name {sortConfig.key === 'propertyName' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                            </th>
                            <th style={{textAlign:'center',padding:'.5rem'}}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentTableData.length > 0 && 
                        <>
                            {currentTableData.length > 0 && data.map((val, key) => (
                            <tr key={key} style={{background:'#fffff'}}>
                                <td style={{textAlign:'center',padding:'.5rem'}}><input type='radio'/></td>
                                <td style={{textAlign:'center',padding:'.5rem'}}>{val.uuid}</td>
                                <td style={{textAlign:'center',padding:'.5rem'}}>{'admin'}</td> 
                                <td style={{textAlign:'center',padding:'.5rem'}}>{val.deal_type_id}</td>
                                <td style={{textAlign:'center',padding:'.5rem'}}>{val.propertyName}</td>
                                <td style={{textAlign:'center',padding:'.5rem', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                {val.status === "1" && renderliveButton(val)}
                                {val.status === "0" && renderPublishButton(val)}
                                {val.status === "2" && renderArchieveButton(val)}
                                <img style={{margin:'0 1rem',cursor:'pointer'}} width={20} height={20} src={edit} alt="" onClick={() => handleEdit(val)} />
                                <img onClick={() => deleteListWarning(val._id)} style={{margin:'0 1rem', cursor:'pointer'}} width={20} height={20} src={delete_img} alt="" />
                                </td>
                            </tr>
                            ))}
                        </>
                        
                        
                        }
                        </tbody>
                    </table> */}
                    {currentTableData.length === 0 && 
                    <div style={{background:'#fffff'}}>
                                <h2 style={{textAlign:'center',padding:'.5rem'}}>No data found !!</h2>
                        </div>}
                    <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={data.length}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                        />
                    {/* <div className="paginate">
                        <button> <img style={{margin:'0 1rem'}} width={10} height={10} src={liftarrow} alt="" />Back</button>
                        
                        <div style={{ margin:'1rem', display:'flex', alignItems:'center', justifyContent:'space-between',width:'auto' }}>
                            <p className="active">1</p>
                            <p>2</p>
                            <p>3</p>
                        </div>
                        <button> Next <img style={{margin:'0 1rem'}} width={10} height={10} src={rightarrow} alt="" /></button>
                    </div> */}
                </div>

                </div>

            </div>
        </div>

        <div className="footer" style={{ background:'black', width:'100%', padding:'1rem', marginTop:'2rem' }}>
            <h2 style={{ textAlign:'center', color:'white' }}>Collaborate with Team Mates, Any Where, Any Time.</h2>
        </div>
        
        </div>
        
        </>
     );
}

export default ListingOview;