import apiconnection from "../api/general";

const teams = async () => {
  const response = await apiconnection.get(
    `/feature/teams`
  );
  return response.data;
};
const roles = async () => {
  const response = await apiconnection.get(
    `/feature/roles`
  );
  return response.data;
};

const featureService = {
  teams,
  roles
};

export default featureService;
