import React, { useEffect, useState } from "react";
// import EmailLogo from '../../assets/EmailLogo.svg'
import BigTriangle from "../../assets/BigTriangle.svg";
import SmallTriangle from "../../assets/SmallTriangle.svg";
import "./CreateEmail.css";
import EmailPreview from "./EmailPreview";
import {Link, useOutletContext} from "react-router-dom"

function CreateEmail() {

const {emailKeywords, setEmailTitle, setEmailDescription,setHeader,setOrg, setWebsite,setEmail, setPhone, addTags, removeTag} = useOutletContext()

  

  return (
    <>
      

      <form className="email-form-section">
      <div className="email-form-input">
          <label htmlFor="email-titile">Header</label>
          <input
            style={{height:'30px'}}
            type="text"
            id="email-title"
            name="emailTitle"
            onChange={(e) => setHeader(e.target.value)}
          />
        </div>
        <div>
        <div className="email-form-input">
            <label htmlFor="email-titile">Socails</label>
        </div>
        <div  style={{ display:'flex', flexWrap:'wrap', width:'450px', justifyContent:'space-between'}}>
      <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Phone</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Email</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Website</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>
      <div className="" style={{display:'flex', flexDirection:'column', justifyContent:"space-between", marginBottom:'.4rem'}}>
          <label htmlFor="email-titile">Orgasation</label>
          <input
            style={{height:'30px', padding:"0 1rem"}}
            type="text"
            id="email-title"
            name="emailTitle"
            onChange={(e) => setOrg(e.target.value)}
          />
        </div>

        </div>


        </div>
        
        
        <div className="email-form-input">
          <label htmlFor="email-titile">Add Title</label>
          <textarea
            type="text"
            id="email-title"
            name="emailTitle"
            onChange={(e) => setEmailTitle(e.target.value)}
          />
        </div>
        <div className="email-form-input">
          <label htmlFor="email-description">Add Short Description</label>
          <textarea
            type="text"
            id="email-description"
            name="emailDescription"
            onChange={(e) => setEmailDescription(e.target.value)}
          />
        </div>
        <div className="email-form-input">
          <label htmlFor="email-keywords">Add Key words</label>
          <textarea
            type="text"
            id="email-keywords"
            name="emailKeywords"
            onKeyUp={addTags}
          />
          <div className="keyword-tags-section">
            <ul className="tag-wrapper">
              {emailKeywords.map((tags, index) => (
                <li
                  className="keyword-tag"
                  key={index}
                >
                  {tags}
                  <button
                    onClick={() => removeTag(index)}
                    className="tag-close-btn"
                  >
                    x
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateEmail;
