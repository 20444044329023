import React from 'react'; 
import classnames from 'classnames'; 
import { usePagination, DOTS } from './usePagination'; 

import leftarrow from '../../../assets/leftarrow.png'
import rightarrow from '../../../assets/rightarrow.png'
// import './pagination.scss'; 
const Pagination = props => { 
  const { 
    onPageChange, 
    totalCount, 
    siblingCount = 1, 
    currentPage, 
    pageSize, 
    className 
  } = props; 
 
  const paginationRange = usePagination({ 
    currentPage, 
    totalCount, 
    siblingCount, 
    pageSize 
  }); 
 
  if (currentPage === 0 || paginationRange.length < 2) { 
    return null; 
  } 
 
  const onNext = () => { 
    onPageChange(currentPage + 1); 
  }; 
 
  const onPrevious = () => { 
    onPageChange(currentPage - 1); 
  }; 
 
  let lastPage = paginationRange[paginationRange.length - 1]; 
  return ( 
    <ul 
      className={classnames('pagination-container', { [className]: className })} 
    > 

    {/* <div className="paginate">
                        <button> <img style={{margin:'0 1rem'}} width={10} height={10} src={liftarrow} alt="" />Back</button>
                        
                        <div style={{ margin:'1rem', display:'flex', alignItems:'center', justifyContent:'space-between',width:'auto' }}>
                            <p className="active">1</p>
                            <p>2</p>
                            <p>3</p>
                        </div>
                        <button> Next <img style={{margin:'0 1rem'}} width={10} height={10} src={rightarrow} alt="" /></button>
                    </div> */}
      <li 
        className={classnames('pagination-item arrow', { 
          disabled: currentPage === 1 
        })} 
        onClick={onPrevious} 
      > 
        <div className="arrow left" >
          
          <p style={{ display:'flex', alignItems:'center' }}><img src={leftarrow} alt="" style={{ margin:'0 .5rem' }} />Back</p> </div>
      </li> 
      {paginationRange.map(pageNumber => { 
        if (pageNumber === DOTS) { 
          return <li className="pagination-item dots">&#8230;</li>; 
        } 
 
        return ( 
          <li 
            className={classnames('pagination-item', { 
              selected: pageNumber === currentPage 
            })} 
            onClick={() => onPageChange(pageNumber)} 
          > 
            {pageNumber} 
          </li> 
        ); 
      })} 
      <li 
        className={classnames('pagination-item arrow', { 
          disabled: currentPage === lastPage 
        })} 
        onClick={onNext} 
      > 
        <div className="arrow right"><p style={{ display:'flex', alignItems:'center' }}>Next <img src={rightarrow} alt="" style={{ margin:'0 .5rem' }} /></p> </div>
      </li> 
    </ul> 
  ); 
}; 
 
export default Pagination;