import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/login/loginPage";
import Reset from "./pages/login/ResetPassword";
import EmailValidate from "./pages/login/EmailVerification"
import ListingOview from "./pages/dashboard/listing/ListingOview";

import OnboardingPage from "./pages/onboarding/OnboardingPage";
import ProtectedRoute from "./protectedRoute";

import WorkSpace from "./pages/dashboard/WorkSpace/WorkSpace";
import Home from "./pages/dashboard/Home/Home";

import CreateEmail from "./pages/marketing/CreateEmail";
import SecondSection from "./pages/marketing/SecondSection";
import EmailPreview from "./pages/marketing/EmailPreview";

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/listing" element={<ProtectedRoute><ListingOview /></ProtectedRoute>}/>
      <Route path="/" element={<ProtectedRoute><Home/></ProtectedRoute>} />
      <Route path="/workspace" element={<ProtectedRoute><WorkSpace/></ProtectedRoute>} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/onboarding" element={<OnboardingPage />} />
      <Route path="validate/:id" element={<EmailValidate />} />
      <Route path="/marketing" element={<EmailPreview />}>
        <Route index element={<CreateEmail />} />
        <Route path="page2" element={<SecondSection />} />
      </Route>
      {/* <Route path="*" element={<NoPage />} /> */}
    </Routes>
  </BrowserRouter>
  );
}

export default App;
