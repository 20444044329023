
import React, { useState, useCallback,useMemo, useEffect,useRef } from "react";

import paypal from '../../assets/Paypal.png'
import cheque from '../../assets/cheque.png'
import momo from '../../assets/momo.webp'
import cash from '../../assets/cash.png'
import mastercard from '../../assets/Mastercard.png'
import visa from '../../assets/Visa.png'
import googlemap from '../../assets/googlemap.png'
import location from '../../assets/location.png'
import filter from '../../assets/filter.png'
import frame from '../../assets/Frame.png'
import xcelsz from '../../assets/xcelsz.png'


import * as Yup from "yup";
import { useFormik,Field  } from "formik";

import "../../pages/dashboard/listing/listOview.css";
import axios from 'axios';
import { UploadButton } from "@bytescale/upload-widget-react";

const options = {
    apiKey: "free", //"public_FW25brM9kSSiy5Gd5VXC2qKy8vzi", // Get API key: https://www.bytescale.com/get-started
    maxFileCount: 1,
    mimeTypes: ["image/*"],
    editor: {
      images: {
        allowResizeOnMove: true,
        crop: true,
        // cropFilePath: ,
        cropRatio: 1.778,
        // cropShape: "circ",
        preview: true,
      },
    },
  };


function EditDeals({data, closeModel, alert, updatelist}) {
    // const [dealVisibility, setDealVisibility] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mediaUrl, setMediaUrl] = useState(data.media_url);

    const formik = useFormik({
        initialValues: {
          propertyName: data.propertyName,
          propertyType: data.propertyType,
          propertyCategory: data.propertyCategory,
          propertySize: data.propertySize,
          propertyPrice: data.propertyPrice,
          propertyUse: data.propertyUse,
          room:data.room,
          beds:data.beds,
          parkingSpace:data.parkingSpace,
          furnishedStatus:data.furnishedStatus,
          residentialAddress:data.residentialAddress,
          googleMapsAddress:data.googleMapsAddress,
        //   propertyMediaFiles:"",
          summary:data.summary,
          keywords:data.keywords,
          paymentMethod:data.payment_mode.split(",")
        },
        validationSchema: Yup.object({
        propertyName:  Yup.string()
            .required("Property Name is required")
            .label("Property Name"),
        propertyType:  Yup.string()
            .required("Property Type is required")
            .label("Property Type"),
        propertyCategory:  Yup.string()
            .required("Property Category is required")
            .label("Property Category"),
        propertySize:  Yup.string()
            .required("Property Size is required")
            .label("Property Size"),
        propertyPrice:  Yup.string()
            .required("Property Price is required")
            .label("Property Price"),
        // propertyUse: Yup.array()
        //     .label("Property Use")
        //     .max(1, "At least one input must be selected")
        //     .min(1, "At least one input must be selected")
        //     .required("Property Use is required"),
        propertyUse: Yup.string().required('You must select an option'),
        room: Yup.string()
            .required("Room is required")
            .label("Room"),
        beds: Yup.string()
            .required("Beds is required")
            .label("Beds"),
        parkingSpace: Yup.string()
            .required("Parking Space is required")
            .label("Parking Space"),
        furnishedStatus: Yup.string().required('You must select furnished status '),
        
        paymentMethod: Yup.array().min(1,'At least one payment option must be selected'),
        // furnishedStatus: Yup.string()
        //     // .required("Furnished Status is required")
        //     .label("Furnished Status")
        //     .max(1, "At least one input must be selected")
        //     .min(1, "At least one input must be selected")
        //     .required("Furnished Status is required"),
        residentialAddress: Yup.string()
            .required("Email address is required")
            .label("Email address"),
        googleMapsAddress: Yup.string()
            .required("Google Maps Address is required")
            .label("Google Maps Address"),
        // propertyMediaFiles: Yup.string()
        //     .required("Property Media Files is required")
        //     .label("Property Media Files"),
        summary: Yup.string()
            .required("Summary is required")
            .label("Summary"),
        keywords: Yup.string()
            .required("Keywords is required")
            .label("Keywords")
        }),
        // paymentMethod: Yup.boolean()
        //     .oneOf(['credit', 'paypal','momo'], 'Payment method is required')
        //     .required('Payment method is required')
        //     .label("Payment method"),
    
        onSubmit: async (values, {resetForm}) => {
            console.log('1');
            
            setLoading(true)
            const selectedPaymentMethods = values.paymentMethod.join(',');
            const uuid = Math.floor(Math.random() * 900) + 100;

            const formData = new FormData();
        formData.append('media_url', JSON.stringify(mediaUrl));
         formData.append('propertyName',values.propertyName);
         formData.append('uuid',`list${uuid}`);
        //  formData.append('deal_type_id', valuesdealType_id);
        //  formData.append('deal_type_id', dealType_id);
         formData.append('propertyType',values.propertyType);
         formData.append('propertyCategory',values.propertyCategory);
         formData.append('propertySize',values.propertySize);
         formData.append('propertyPrice',values.propertyPrice);
         formData.append('propertyUse',values.propertyUse);
         formData.append('room',values.room);
         formData.append('beds',values.beds);
         formData.append('parkingSpace',values.parkingSpace);
         formData.append('furnishedStatus',values.furnishedStatus);
         formData.append('residentialAddress',values.residentialAddress);
         formData.append('googleMapsAddress',values.googleMapsAddress);
        //  formData.append('propertyMediaFiles',values.propertyMediaFiles);
         formData.append('summary',values.summary);
         formData.append('keywords',values.keywords);
         formData.append('payment_mode',selectedPaymentMethods);
    
    
            try {
                console.log('2');
                const response = await axios.patch(
                  `https://ihubbackend2.xcelsz.com/api/listing/edit/${data._id}`,
                  formData,{
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }
                );
                console.log(response.data);
                updatelist()
                closeModel(false)
                alert('Property Deal Edited')
                setLoading(false)
                resetForm()
              } catch (error) {
                alert('Property Deal Edit Failed')
                setLoading(false)
                console.error('Error uploading file:', error);
              }
            setLoading(false)
        }
      });



    const RemoveImage = (ele)=>{
        const updatedArray = mediaUrl.filter(element => element !== ele);
        setMediaUrl(updatedArray);
    }

    //   https://ihubbackend2.xcelsz.com/api/listing/edit/:id
    return ( 
        <>
            <div style={{ position:'fixed', height:'100vh', width:'100%', background:'rgba(0,0,0,.4)',zIndex:9999, top:0, left:0, right:0, button:0 }}>
                <div style={{ background:'white', width:'50%', height:'100%', margin:'2rem auto', borderRadius:'1rem', padding:'2rem', overflowY:'scroll' }}>
                    <form style={{ marginBottom:'2rem' }} onSubmit={formik.handleSubmit}>
                        <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between' }}>
                            <h2>{`Edit Deal`}</h2>
                            <div onClick={(prev)=>{closeModel(false)}} style={{ background:'#FBF6E4', width:40, height:40, borderRadius:'50%',display:'flex', alignItems:'center', justifyContent:'center' }}>
                                <h2 style={{ cursor:'pointer' }}>x</h2>
                            </div>
                        </div>
                        <span style={{ padding:'3rem 0' }}>General Information</span>
                        
                        <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Name</label>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyName} type="text" name="propertyName" id="propertyName" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Enter property name" />
                            {formik.touched.propertyName && formik.errors.propertyName ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyName}</p>
                            ) : null}
                        </div>
                        <div style={{ marginTop:'10px', flexDirection:'row', display:'flex', gap:'1rem' }}>
                            <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Type</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyType} type="text" name="propertyType" id="propertyType" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Select  property type. eg;Apartment" />
                                {formik.touched.propertyType && formik.errors.propertyType ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyType}</p>
                            ) : null}
                            </div>
                            <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Category</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyCategory} type="text" name="propertyCategory" id="propertyCategory" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Select category. eg;B2S" />
                                {formik.touched.propertyCategory && formik.errors.propertyCategory ? (
                                <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyCategory}</p>
                            ) : null}
                            </div>
                        </div>
                        
                        <div style={{ marginTop:'10px', flexDirection:'row', display:'flex', gap:'3rem' }}>
                            <div style={{ flexDirection:'row', display:'flex', width:'50%',gap:'1rem' }}>
                                <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                                    <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Size ( m² )</label>
                                    <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertySize} type="number" name="propertySize" id="propertySize" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                    {formik.touched.propertySize && formik.errors.propertySize ? (
                                        <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertySize}</p>
                                    ) : null}
                                </div>
                                <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Price ( USD )</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.propertyPrice} type="number" name="propertyPrice" id="propertyPrice" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                {formik.touched.propertyPrice && formik.errors.propertyPrice ? (
                                        <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyPrice}</p>
                                    ) : null}
                                </div>
                            
                            </div>
                            <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Use</label>
                                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                                    <div style={{ display:'flex', alignItems:'center' }}>
                                        <input type="radio" name="propertyUse" id="" value='Commercial' style={{ margin:'1rem' }} onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.propertyUse === 'Commercial'}/>
                                        <p>Commercial</p>
                                    </div>
                                    <div style={{ display:'flex', alignItems:'center' }}>
                                        <input type="radio" name="propertyUse" id="" value='Residential' style={{ margin:'1rem' }} onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.propertyUse === 'Residential'}/>
                                        <p>Residential</p>
                                    </div>



                                </div>
                                {formik.touched.propertyUse && formik.errors.propertyUse ? (
                                        <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.propertyUse}</p>
                                    ) : null}
                            </div>
                        </div>

                        <div style={{ marginTop:'10px', flexDirection:'row', display:'flex', gap:'3rem' }}>
                            <div style={{ flexDirection:'row', display:'flex', width:'50%',gap:'1rem' }}>
                                <div style={{ flexDirection:'column', display:'flex', width:'30%' }}>
                                    <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Bed room
                                    </label>
                                    <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.room} type="number" name="room" id="room" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                    {formik.touched.room && formik.errors.room ? (
                                        <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.room}</p>
                                    ) : null}
                                </div>
                                <div style={{ flexDirection:'column', display:'flex', width:'30%' }}>
                                    <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Bath</label>
                                    <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.beds} type="number" name="beds" id="beds" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                    {formik.touched.beds && formik.errors.beds ? (
                                        <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.beds}</p>
                                    ) : null}
                                </div>
                                <div style={{ flexDirection:'column', display:'flex', width:'30%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Parking Space</label>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.parkingSpace} type="number" name="parkingSpace" id="parkingSpace" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="0" />
                                {formik.touched.parkingSpace && formik.errors.parkingSpace ? (
                                        <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.parkingSpace}</p>
                                    ) : null}
                                </div>
                            
                            </div>
                            <div style={{ flexDirection:'column', display:'flex', width:'50%' }}>
                                <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Furnished Status</label>
                                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                                    <div style={{ display:'flex', alignItems:'center' }}>
                                        <input type="radio" name="furnishedStatus" value='yes' id="furnishedStatusYes" style={{ margin:'1rem' }}  onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.furnishedStatus === 'yes'}/>
                                        <p>Yes</p>
                                    </div>
                                    <div style={{ display:'flex', alignItems:'center' }}>
                                        <input type="radio" name="furnishedStatus" value='no' id="furnishedStatusNo" style={{ margin:'1rem' }} onChange={formik.handleChange} onBlur={formik.handleBlur} checked={formik.values.furnishedStatus === 'no'}/>
                                        <p>No</p>
                                    </div>

                                </div>
                                {formik.touched.furnishedStatus && formik.errors.furnishedStatus ? (
                                        <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.furnishedStatus}</p>
                                    ) : null}
                            </div>
                        </div>

                        <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Residential Address</label>
                            <div style={{ position:'relative', width:'100%' }}>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.residentialAddress} type="text" name="residentialAddress" id="residentialAddress" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', width:'100%' }} placeholder="Enter residential address" />
                                <img src={location} width={20} height={20} alt="" style={{ position:'absolute',right:'1rem', top:'2rem' }}/>
                            </div>
                            {formik.touched.residentialAddress && formik.errors.residentialAddress ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.residentialAddress}</p>
                                ) : null}
                        
                        </div>
                        <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Google Maps Address</label>
                            <div style={{ position:'relative', width:'100%' }}>
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.googleMapsAddress} type="text" name="googleMapsAddress" id="googleMapsAddress" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', width:'100%' }} placeholder="Enter google maps address" />
                                <img src={googlemap} width={25} height={25} alt="" style={{ position:'absolute',right:'1rem', top:'1.5rem' }}/>
                            </div>
                            {formik.touched.googleMapsAddress && formik.errors.googleMapsAddress ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.googleMapsAddress}</p>
                                ) : null}
                        </div>
                        <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                        <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Property Media Files</label>
                        <section className="container">
                        <UploadButton
                            options={options}
                            onComplete={(files) =>{
                                const url = files.map((x) => x.fileUrl).join("\n")
                                if (url !=='') {
                                    setMediaUrl((prev)=>([...prev,url]))
                                }

                            }
                            }
                        >
                            {({ onClick }) => (
                            // <li title="add image">
                            //     <img src={edit} onClick={onClick} alt="gallery" />
                            // </li>
                            <div onClick={onClick}>
                            {/* <div onClick={onClick} {...getRootProps({className: 'dropzone'})}> */}
                                {/* <input {...getInputProps()}  /> */}
                                <div style={{ padding:'2rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'center' }}>
                                    <img width={70} height={25} src={frame} alt="" style={{ margin:'1rem' }} />
                                    <p ><span style={{ color:'#FAC710' }}>Click here </span>to upload or drop files here</p>
                                </div>
                            </div>
                
                    )}
                        </UploadButton>
                        {/* <div onClick={onClick} {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()}  />
                            <div style={{ padding:'2rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'center' }}>
                                <img width={70} height={25} src={frame} alt="" style={{ margin:'1rem' }} />
                                <p ><span style={{ color:'#FAC710' }}>Click here </span>to upload or drop files here</p>
                            </div>
                        </div> */}
                            
                            {mediaUrl.length > 0 && <aside>
                                <h4>Files</h4>
                                <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start',flexWrap:'wrap', gap:'1rem'}}>
                                    {mediaUrl.map((val,index)=>{
                                        return(
                                        <ul style={{position:'relative', padding:'0rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', boxShadow:'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', fontSize:'1rem', textAlign:'left', display:'flex', alignItems:'center', width:200 }}><img width={'100%'} style={{ borderRadius:'.5rem' }} height={100} src={val} alt="" /> <span onClick={()=>RemoveImage(val)} style={{ position:'absolute', right:'.4rem', fontSize:'1.5rem', fontWeight:'bold', zIndex:99, color:'red',top:'0rem', cursor:'pointer' }}>x</span></ul>
                                        )
                                    })}
                                </div>
                                
                                
                                {/* <ul style={{position:'relative', padding:'.5rem 1rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', textAlign:'left', display:'flex', alignItems:'center' }}><img width={25} height={25} src={file} alt="" />{files} <span style={{ position:'absolute', right:'1rem', fontSize:'1rem', fontWeight:'bold' }}>x</span></ul> */}
                            </aside>}
                            
                            </section>
                    </div>
                    
                        <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Deal Summary</label>
                            <p style={{ fontSize:'12px' }}>A short summary listing the key features and amenities of the deal</p>
                            <textarea onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.summary} type="text" name="summary" id="summary" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} />
                            {formik.touched.summary && formik.errors.summary ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.summary}</p>
                                ) : null}
                        </div>
                        <div style={{ marginTop:'10px', flexDirection:'column', display:'flex' }}>
                            <label htmlFor="" style={{ fontSize:'.8rem', fontWeight:'bold' }}>Keywords</label>
                            <div style={{ flexDirection:'row', display:'flex', gap:'1rem' }}>
                            {/* {keywords.map(()=>{
                                <p style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:100 }}>Eco-Friendly <span style={{ marginLeft:'1rem' }}>X</span></p>
                            })} */}
                    
                            {/* <p style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem', minWidth:100 }}>Luxury Home <span style={{ marginLeft:'1rem' }}>X</span></p> */}
                            </div>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.keywords} type="text" name="keywords" id="keywords" style={{ padding:'.8rem', borderRadius:'.5rem', margin:'1rem 0', background:'#FBF6E4', border:'2px solid #D3CFC0', fontSize:'1rem' }} placeholder="Enter or Select keywords. eg; verified , affordable, unregistered, quality assured" />
                            {formik.touched.keywords && formik.errors.keywords ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.keywords}</p>
                                ) : null}
                        </div>

                        <label htmlFor="">Payment Method Options</label>
                        {/* <div style={{ display:'flex', alignItems:'center' }}>
                
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} type="radio" name="paymentMethod" id="" style={{ margin:'1rem' }}  checked={formik.values.paymentMethod === 'Card'}/>
                            <p>Credit card or Debit card</p>
                            <img width={70} height={25} src={mastercard} alt="" style={{ margin:'1rem' }} />
                            <img width={70} height={25} src={visa} alt="" style={{ margin:'1rem' }} />
                        </div>
                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} type="radio" name="paymentMethod" id="" style={{ margin:'1rem' }} checked={formik.values.paymentMethod === 'PayPal'} />
                            <p>PayPal</p>
                            <img width={70} height={25} src={paypal} alt="" style={{ margin:'1rem' }} />
                        </div> */}


                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                type="checkbox" 
                                name="paymentMethod" 
                                value="Card" 
                                id="" 
                                checked={formik.values.paymentMethod.includes('Card')}
                                style={{ marginRight:'1rem', display:'flex' }} 
                            />
                            <p>Credit card or Debit card</p>
                            <img width={70} height={25} src={mastercard} alt="" style={{ margin:'1rem' }} />
                            <img width={70} height={25} src={visa} alt="" style={{ margin:'1rem' }} />
                         </div>

                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                type="checkbox" 
                                name="paymentMethod" 
                                value="PayPal" 
                                checked={formik.values.paymentMethod.includes('PayPal')}
                                id="" 
                                style={{ marginRight:'1rem', display:'flex' }} 
                            />
                            <p>PayPal</p>
                            <img width={70} height={25} src={paypal} alt="" style={{ margin:'1rem' }} />
                        </div>
                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                type="checkbox" 
                                name="paymentMethod" 
                                value="cheque" 
                                checked={formik.values.paymentMethod.includes('cheque')}
                                id="" 
                                style={{ marginRight:'1rem', display:'flex' }} 
                            />
                            <p>Cheque</p>
                            <img width={50} height={35} src={cheque} alt="" style={{ margin:'1rem' }} />
                        </div>
                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                type="checkbox" 
                                name="paymentMethod" 
                                value="cash" 
                                checked={formik.values.paymentMethod.includes('cash')}
                                id="" 
                                style={{ marginRight:'1rem', display:'flex' }} 
                            />
                            <p>Cash Deposit</p>
                            <img width={50} height={35} src={cash} alt="" style={{ margin:'1rem' }} />
                        </div>
                        <div style={{ display:'flex', alignItems:'center' }}>
                            <input 
                                onChange={formik.handleChange} 
                                onBlur={formik.handleBlur} 
                                type="checkbox" 
                                name="paymentMethod" 
                                value="momo" 
                                checked={formik.values.paymentMethod.includes('momo')}
                                id="" 
                                style={{ marginRight:'1rem', display:'flex' }} 
                            />
                            <p>Mobile Money</p>
                            <img width={70} height={35} src={momo} alt="" style={{ margin:'1rem' }} />
                        </div>

             
                        {formik.touched.paymentMethod && formik.errors.paymentMethod ? (
                                    <p style={{color: "red",marginTop: "5px",fontSize: "13px",marginLeft: "3px",marginBottom: "0",fontFamily: "SFmedium",marginBottom:'8px'}}>{formik.errors.paymentMethod}</p>
                                ) : null}

                        <div className="forms-btn" style={{ display:'flex', alignItems:'center', width:'100%', justifyContent:'space-between' }}>
                            <button type="button" style={{ cursor:'pointer' }} className="cancel" onClick={(prev)=>{closeModel(false)}}>Cancel</button>
                            {!loading?<button type="submit" className="save">Edit</button>:<button disabled type="submit" className="save">Loading ...</button>}
                            
                        </div>
                    </form>
                </div>
            </div>
        </>
     );
}

export default EditDeals;