import React,{useState, useEffect, useRef} from "react";
import { useNavigate,Link, useParams,useLocation } from "react-router-dom";
import "./onboarding.css";
// import LoginFormContainer from "../../Components/LoginContainer/LoginFormContainer"
import OnboardFormContainer from "../../Components/OnboardContainer/OnboardFormContainer";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';

import Img from "../../assets/photo.jpg"

import loginService from "../../https/login/loginHttp";


const OnboardingPage = () => {

  const notify = (msg) => toast(msg);

  const [email, setEmail] = useState();
  const [userdata, setUserData] = useState();
  const formRef = useRef();
  const inputRefs = useRef([]);
  const [inputs, setInputs] = useState(Array(6).fill('')); // Assuming 6 inputs

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(generateSixDigitNumber())
  const [section, setSection] = useState('login')

  const sendOpt = ()=>{
    console.log(code);
    console.log(formRef.current);
    if (formRef.current) {
      emailjs.sendForm('service_fgkw7se', 'template_i7d8oxc', formRef.current, 'Q-OEDDAj6WqOzNCd8')
      .then((result) => {
        console.log('working');
      }, (error) => {
          console.log(error);
      });
    }
    
  }
  const handleLogin = async (data) => {
    setLoading(true)
    const new_code = inputs.join('')
    let res = parseInt(new_code,10)

  if (new_code === '' || new_code.length < 6) {
    console.log('enter inputs');
    notify("Invalid OPT Code")
    setLoading(false)
    
  }else{
    if (code == res) {
      // localStorage.setItem('ihub_user', JSON.stringify(userdata))
     
      try {
        const res = await loginService.onboarding(userdata);
        console.log(res);
        setLoading(false)
        notify("Onboarding was successfull")
         navigate("/");
      } catch (error) {
        console.log(error);
         setLoading(false)
         notify(error.response.data.error)
      }
    }else{
      notify("Invalid OPT Code")
      setLoading(false)
    }
  }
  
    
  };

  function generateSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

 

  const handleInput = (e, index) => {
    const value = e.target.value;
    console.log(e.target.value);
    
    if (isNaN(value)) {
      return;
    }

    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    if (value !== '' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyUp = (e, index) => {
    const key = e.key.toLowerCase();

    if (key === 'backspace' || key === 'delete') {
      const newInputs = [...inputs];
      newInputs[index] = '';
      setInputs(newInputs);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  return (

    <>
    <form ref={formRef} >
            <input type="hidden" name="code" value={code} />
            {/* <input type="hidden" name="staff_email" value='harpnkansah@gmail.com' /> */}
            <input type="hidden" name="staff_email" value={`${email}`} />
        </form>
    {section === 'login' && 
    <div>
        <div className="onboarding-page">
    
    <ToastContainer />
   
   <h1 style={{fontSize:'2.6rem'}}>
     Welcome to <a>a new</a> work week
   </h1>
   <div className="inner-page" style={{ marginBottom:'3rem' }}>
     <h2 className="login-header">
       A whole new productivity journey starts right here with your one-stop
       Virtual WorkSpace
     </h2>
     <OnboardFormContainer notification={notify} updateEmail={setEmail} updateSection={setSection} updateUserData={setUserData} sendcode={sendOpt}/>
   </div>
   
   </div>

    <img src={Img} alt="" width={170} height={200} style={{position:'fixed', bottom:'0rem', right:0}} />
     <div style={{position:'fixed', width:'100%', bottom:0, padding:'.5rem 3rem', display:'flex', justifyContent:'space-between', alignItems:'center', borderTop:'1px solid #000000', background:'#fff', zIndex:99}}>
     <span style={{fontSize:'1.6rem', fontWeight:'bold'}}>
      Already Onboard?
     </span>
     <Link to='/' className="onboard-button">Login In</Link>
     {/* <button type="submit" className="onboard-button">
         Onboard Now
       </button> */}
     </div>
    </div>
    
    }
    
    
      {section === 'opt' && 
        <div className="login-page">
          <ToastContainer />
      <h1 style={{fontSize:'2.6rem'}}>
        Welcome to <a>a new</a> work week
      </h1>
      <div className="inner-page">
        <h2 className="login-header">
          A whole new productivity journey starts right here with your one-stop
          Virtual WorkSpace
        </h2>
        
        <form id="varify-forms">
            <h2 style={{fontSize:'1.3rem'}}>Confirm Work Email</h2>
            <p style={{fontWeight:'bold'}}>Please enter your verification code.</p>
            <span>We have sent a verification code to your registered email address</span>
            <div className="" style={{margin:'1rem 0'}}>
          
              <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'300px'}}>
              
                {inputs.map((input, index) => (
                <input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  value={input}
                  onChange={(e) => handleInput(e, index)}
                  onKeyUp={(e) => handleKeyUp(e, index)}
                  maxLength={1}
                  style={{width:'13%', fontSize:'1rem', height:'35px', borderRadius:'5px', border:'.5px solid grey', textAlign:'center'}}
                />
          ))}
              </div>
            </div>
          
            {loading?
            <button type="button" disabled className="loading-button">
                Verification  ....
          </button>
            :
            <button type="button" className="login-button" onClick={handleLogin}>
              Confirm
            </button>
            }
            <span class="recover-password" >Resend Code</span>
          </form>
        {/* <LoginFormContainer notification={notify} /> */}
      </div>
      <img src={Img} alt="" width={170} height={200} style={{position:'fixed', bottom:'0rem', right:0}} />
      <div style={{position:'fixed', width:'100%', bottom:0, padding:'.5rem 3rem', display:'flex', justifyContent:'space-between', alignItems:'center', borderTop:'1px solid #000000', background:'#fff', zIndex:99}}>
      <span style={{fontSize:'1.6rem', fontWeight:'bold'}}>
       Already Onboard?
      </span>
      <Link to='/' className="onboard-button">Login In</Link>
     
      </div>
      
        </div>}
      

     
    
    </>


  );
};

export default OnboardingPage;
