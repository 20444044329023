import axios from "axios";

export default axios.create({
//   baseURL: "",
  // baseURL: "https://ihub-new-backend.onrender.com/api",
  // baseURL: "https://162.240.210.115:1717/api",
  baseURL: "https://ihubbackend2.xcelsz.com/api",
  headers: {
    "Content-type": "application/json"
  }
});