import React,{useState, useEffect, useRef} from "react";
import { useNavigate,Link, useParams,useLocation } from "react-router-dom";
import "./loginStyles.css";
import 'react-toastify/dist/ReactToastify.css';
import Img from "../../assets/photo.jpg"
import emailjs from 'emailjs-com';

import loginService from "../../https/login/loginHttp";

const EmailVerification = () => {
  const params = useParams()
  const [email, setEmail] = useState(params.id);
  const formRef = useRef();
  const inputRefs = useRef([]);
  const [inputs, setInputs] = useState(Array(6).fill('')); // Assuming 6 inputs

  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(generateSixDigitNumber())
  const [result, setResult] = useState('')
  const [staffEmail, setStaffEmail] = useState('')

  const sendOpt = ()=>{
    emailjs.sendForm('service_fgkw7se', 'template_i7d8oxc', formRef.current, 'Q-OEDDAj6WqOzNCd8')
      .then((result) => {
        console.log('working');
      }, (error) => {
          console.log(error.text);
      });
  }
  const handleLogin = async (data) => {
    setLoading(true)
    const new_code = inputs.join('')
    let res = parseInt(new_code,10)
    console.log(res);
    console.log(new_code);
    console.log(inputs.length);

  if (new_code === '' || new_code.length < 6) {
    console.log('enter inputs');
    
  }else{
    if (code == res) {
      console.log('correct');
      try {
        const res = await loginService.login(data);
        console.log(res);
        setLoading(false)
        // notification("Welcome Login Successfull")
      } catch (error) {
        console.log(error.response["data"]);
        
        setLoading(false)
      }
    }else{
      console.log('wrong');
    }
  }
  
    
  };

  // useEffect(()=>{
  //   const digit = generateSixDigitNumber()
  //   console.log(digit);
  //   setCode(digit)
  //   sendOpt()
  // },[])
  function generateSixDigitNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

 

  const handleInput = (e, index) => {
    const value = e.target.value;
    console.log(e.target.value);
    
    if (isNaN(value)) {
      return;
    }

    const newInputs = [...inputs];
    newInputs[index] = value;
    setInputs(newInputs);

    if (value !== '' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyUp = (e, index) => {
    const key = e.key.toLowerCase();

    if (key === 'backspace' || key === 'delete') {
      const newInputs = [...inputs];
      newInputs[index] = '';
      setInputs(newInputs);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };


  return (
   <div className="login-page">
   <h1 style={{fontSize:'2.6rem'}}>
     Welcome to <a>a new</a> work week
   </h1>
   <div className="inner-page">
     <h2 className="login-header">
       A whole new productivity journey starts right here with your one-stop
       Virtual WorkSpace
     </h2>
     <form ref={formRef} style={{display:'none'}} >
        <input type="hidden" name="code" value={code} />
        <input type="hidden" name="staff_email" value={email} />
      </form>
     <form id="varify-forms">
        <h2 style={{fontSize:'1.3rem'}}>Confirm Work Email</h2>
        <p style={{fontWeight:'bold'}}>Please enter your verification code.</p>
        <span>We have sent a verification code to your registered email address</span>
        <div className="" style={{margin:'1rem 0'}}>
       
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'300px'}}>
           
            {inputs.map((input, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              value={input}
              onChange={(e) => handleInput(e, index)}
              onKeyUp={(e) => handleKeyUp(e, index)}
              maxLength={1}
              style={{width:'13%', fontSize:'1rem', height:'35px', borderRadius:'5px', border:'.5px solid grey', textAlign:'center'}}
            />
      ))}
          </div>
        </div>
      
        {loading?
        <button type="button" disabled className="loading-button">
            Verification  ....
      </button>
        :
        <button type="button" className="login-button" onClick={handleLogin}>
          Confirm
        </button>
        }
        <span class="recover-password" >Resend Code</span>
      </form>
     {/* <LoginFormContainer notification={notify} /> */}
   </div>
   <img src={Img} alt="" width={170} height={200} style={{position:'absolute', bottom:'0rem', right:0}} />
   <div style={{position:'absolute', width:'100%', bottom:0, padding:'.5rem 3rem', display:'flex', justifyContent:'space-between', alignItems:'center', borderTop:'1px solid #000000', background:'#fff', zIndex:99}}>
   <span style={{fontSize:'1.6rem', fontWeight:'bold'}}>
     New Starter?
   </span>
   <button type="submit" className="onboard-button">
       Onboard Now
     </button>
   </div>
    </div>
  );
};

export default EmailVerification;
