import apiconnection from "../../api/general";

const login = async (body) => {
  console.log(body);
  const response = await apiconnection.post(
    `/auth/login?email=${body.email}&password=${body.password}`
  );
  return response.data;
};
const onboarding = async (body) => {
  const response = await apiconnection.post(
    `/auth/onboard?email=${body.email}&password=${body.password}&role_id=${body.role}&team_id=${body.team}`
  );
  return response.data;
};

const checkIfEmailExits = async (body) => {
  const response = await apiconnection.post(
    `/auth/verifyemail?email=${body.email}`
  );
  return response.data;
};

const loginService = {
  login,
  onboarding,
  checkIfEmailExits
};

export default loginService;
