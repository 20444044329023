import { useState } from "react";
import bell_1 from "../../../assets/bell (1).png";
import bell_4 from "../../../assets/bell (4).png";
import MenuIcon from "../../../assets/bar.svg";
import XcelszLogoIcon from "../../../assets/xcelsz_logo.svg";
import ellipse from "../../../assets/Ellipse.png";
import HomeIcon from "../../../assets/house-icon.svg";
import ListingIcon from "../../../assets/writing-pad-line-icon.svg";

import HandshakeIcon from "../../../assets/Handshake.svg"
import RingIcon from "../../../assets/Engagement ring.svg"
import SocializeIcon from "../../../assets/Interaction.svg"
import ShareIcon from "../../../assets/Share.svg"
import IdeaIcon from "../../../assets/Idea.svg"
import GoalIcon from "../../../assets/Goal.svg"
import UpIcon from "../../../assets/UpSpiral.svg"
import MeetingIcon from "../../../assets/Meeting.svg"
import WorkIcon from "../../../assets/Portfolio.svg"
import ProfileIcon from "../../../assets/profile.svg";
import LogoutIcon from "../../../assets/logout1.svg";
import { useNavigate, Link } from "react-router-dom";

import "./HomeStyles.css";
import { FooterBanner } from "../../../Components/FooterBanner/FooterBanner";

const Home = () => {
const [openMenu, setOpenMenu] = useState(false);
const [isVisibleProfile, setIsVisibleProfile] = useState(false);

const toggleDropdownProfile = () => {
    setIsVisibleProfile(!isVisibleProfile);
};
const navigate = useNavigate();
const logout = () => {
    localStorage.removeItem("ihub_user");
    navigate("/login");
  };

  const toggleDropdown = () => {
    setOpenMenu(!openMenu);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.toLocaleString("default", { month: "long" });
    const year = d.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const today = new Date();
  const formattedDate = formatDate(today);


  return (
    <>
     <div className="main-container" style={{maxWidth:'90%', margin:'0 auto', position:'relative'}}>
      <div className="header" style={{ padding: "1rem" }}>
      <Link to={'/'} style={{ width:'100%', textDecoration:'none', color:'#000000' }}>
        <img
          className="xcelsz-logo"
          src={XcelszLogoIcon}
          alt=""
          width={42}
          height={42}
        />
      
      </Link>
        <div className="right-side-header">
          <div className="icons" style={{ marginRight: "3rem" }}>
            <div className="dropdown-ihubx">
              <img
                className="menu"
                style={{ margin: "0 1rem", cursor: "pointer" }}
                width={20}
                height={20}
                src={MenuIcon}
                alt=""
                onClick={toggleDropdown}
              />
              {openMenu &&  <div style={{display:'flex', flexDirection:'column', gap:'1rem', width:'auto', padding:'1rem' }} className={`dropdown ${openMenu ? "visible" : "hidden"}`}>
                <div className="dropdown-item dropdown-item-home">
                  <span>Onboard</span>
                  <img src={HandshakeIcon} alt="" width={20} />
                </div>
                <div className="dropdown-item dropdown-item-home">
                  <span>Engage</span>
                  <img src={RingIcon} alt="" width={20} />
                </div>
                <div className="dropdown-item dropdown-item-home">
                  <span>Socialize</span>
                  <img src={SocializeIcon} alt="" width={16} />
                </div>
                <div className="dropdown-item dropdown-item-home">
                  <span>Share</span>
                  <img src={ShareIcon} alt="" width={16} />
                </div>
                <div className="dropdown-item dropdown-item-home">
                  <span>Learn</span>
                  <img src={IdeaIcon} alt="" width={16} />
                </div>
                <div className="dropdown-item dropdown-item-home">
                  <span>Up-Skill</span>
                  <img src={GoalIcon} alt="" width={16} />
                </div>
                <div className="dropdown-item dropdown-item-home">
                  <span>Align</span>
                  <img src={UpIcon} alt="" width={16} />
                </div>
                <div className="dropdown-item dropdown-item-home">
                  <span>Meet</span>
                  <img src={MeetingIcon} alt="" width={16} />
                </div>
                <Link to={'/workspace'} style={{ width:'100%', textDecoration:'none', color:'#000000' }}>
                    <div className="dropdown-item dropdown-item-home" onClick={() => navigate("/workspace")}>
                    <span>Work</span>
                    <img src={WorkIcon} alt="" width={16} />
                    </div>
                </Link>
                
              </div>}
             
            </div>
            <img
              style={{ margin: "0 1rem" }}
              width={20}
              height={20}
              src={bell_1}
              alt=""
            />
            <img
              style={{ margin: "0 1rem" }}
              width={20}

height={20}
              src={bell_4}
              alt=""
            />
          </div>
          <div className="icons" style={{ position: "relative" }} onClick={toggleDropdownProfile}>
                    <div
                    className={`dropdown-profile ${isVisibleProfile ? "visible" : "hidden"
                    }`}
                >
                    <div className="dropdown-item">
                    <span>Account</span>
                    <img src={ProfileIcon} width={20} height={20} alt="profile" />
                    </div>
                    <div className="dropdown-item" onClick={logout}>
                    <span>Log out</span>
                    <img src={LogoutIcon} width={20} height={20} alt="logout" />
                    </div>
                </div>
                    <img width={40} height={40} src={ellipse} alt="" />
                </div>
        </div>
      </div>
      <div className="date">
        <p style={{ fontSize:'1rem' }}>{formattedDate}</p>
        </div>
      <div className="welcome-container">
        <h3>Hello! 👋</h3>
        <h2>
          Welcome to a <span>new</span> work week
        </h2>
      </div>
      {/* <FooterBanner /> */}
      <div className="footer" style={{ background:'black', width:'100%', padding:'1rem', marginTop:'2rem', position:'absolute', bottom:'1rem' }}>
            <h2 style={{ textAlign:'center', color:'white' }}>Collaborate with Team Mates, Any Where, Any Time.</h2>
        </div>
        
    </div>
    </>
    
  );
};

export default Home;

