// import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import loginService from "../../https/login/loginHttp";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./OnboardFormContainer.css";

import featureService from "../../https/features";

const OnboardFormContainer = ({notification, updateEmail,updateSection,updateUserData,sendcode}) => {
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [roles, setRoles] = useState([]);
  const [team, setTeam] = useState([]);
  const navigate = useNavigate();
  
  const handleLogin = async (data) => {
    setLoading(true)
    try {
      // const res = await loginService.onboarding(data);
      const res = await loginService.checkIfEmailExits(data);
      console.log(res);
      
      setLoading(false)
      notification("Details Captured Successfull")
      updateUserData(data)
      // updateEmail(data.email)
      updateSection('opt')
      sendcode()
      
    } catch (error) {
      console.log(error);
      
      setLoading(false)
      notification(error.response.data.error)
    }
  };

  const formik = useFormik({
    initialValues: {
      // department: "",
      team: "",
      role: "",
      email: "",
      password: "",
      confirmPassword: "",

    },
    validationSchema: Yup.object({
      // department: Yup.string()
      //   .required("Department address is required")
      //   .label("Department"),
      team: Yup.string()
        .required("Team is required")
        .label("Team"),
      role: Yup.string()
        .required("Role is required")
        .label("Role"),
      email: Yup.string()
        .email("Email address must be valid")
        .matches(/^[A-Za-z0-9._%+-]+@xcelsz\.com$/, 'Email must be from @xcelsz.com')
        .required("Email address is required")
        .label("Email address"),
      password: Yup.string()
        .min(8, "Password should be a minimum of 8 characters")
        .required("Password is required")
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    }),
    onSubmit: (values) => {
      console.log(values);
      handleLogin(values);
    },
  });


  const getRoles = async ()=>{
    try {
      const res = await featureService.roles()
      setRoles(res)
    } catch (error) {
      console.log(error);
      
    }
  }

  const getTeam = async ()=>{
    try {
      const res = await featureService.teams()
      setTeam(res)
    } catch (error) {
      console.log(error);
      
    }
  }

  useEffect(()=>{
    getRoles()
    getTeam()
  },[])


  useEffect(()=>{
    console.log(formik.values.email);
    updateEmail(formik.values.email)
  },[formik.values.email])

  return (
    <>
      {loginError && (
        <p
          style={{
            color: "red",
            marginTop: "5px",
            fontSize: "13px",
            marginLeft: "3px",
            marginBottom: "0",
            fontFamily: "SFmedium",
          }}
        >
          {errorMsg}
        </p>
      )}
      <form id="login-forms" onSubmit={formik.handleSubmit} style={{ marginBottom:'3rem' }}>
        <h2 style={{fontSize:'1.3rem'}}>Onboarding</h2>
        {/* <div className="input-container">
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <label htmlFor="" style={{fontSize:'14px', marginBottom:'8px'}}>Department</label>
          {formik.touched.department && formik.errors.department ? (
          <p
            style={{
              color: "red",
              marginTop: "5px",
              fontSize: "13px",
              marginLeft: "3px",
              marginBottom: "0",
              fontFamily: "SFmedium",
              marginBottom:'8px'
            }}
          >
            {formik.errors.department}
          </p>
        ) : null}
          </div>
    
          <select onChange={formik.handleChange} onBlur={formik.handleBlur}  value={formik.values.department} name="" id="">
            <option value="">Selected Department</option>
            <option value="">Selected Department</option>
          </select>
        </div> */}
        <div className="input-container">
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <label htmlFor="" style={{fontSize:'14px', marginBottom:'8px'}}>Team</label>
          {formik.touched.team && formik.errors.team ? (
          <p
            style={{
              color: "red",
              marginTop: "5px",
              fontSize: "13px",
              marginLeft: "3px",
              marginBottom: "0",
              fontFamily: "SFmedium",
              marginBottom:'8px'
            }}
          >
            {formik.errors.team}
          </p>
        ) : null}
          </div>
         
          <select onChange={formik.handleChange} onBlur={formik.handleBlur}  value={formik.values.team} name="team" id="team">
            <option value="">Selected Team</option>
            {team.map((val, index)=>{
              return(
                <option key={index} value={val._id}>{val.title}</option>
              )
            })}
          </select>
        </div>
        <div className="input-container">
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <label htmlFor="" style={{fontSize:'14px', marginBottom:'8px'}}>Role</label>
          {formik.touched.role && formik.errors.role ? (
          <p
            style={{
              color: "red",
              marginTop: "5px",
              fontSize: "13px",
              marginLeft: "3px",
              marginBottom: "0",
              fontFamily: "SFmedium",
              marginBottom:'8px'
            }}
          >
            {formik.errors.role}
          </p>
        ) : null}
          </div>
          <select onChange={formik.handleChange} onBlur={formik.handleBlur}  value={formik.values.role} name="role" id="role">
            <option value="">Select Role</option>
            {roles.map((val, index)=>{
              return(
                <option key={index} value={val._id}>{val.title}</option>
              )
            })}
            <option value="">Role</option>
          </select>
        </div>
        <div className="input-container">
          <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <label htmlFor="" style={{fontSize:'14px', marginBottom:'8px'}}>Work Email</label>
          {formik.touched.email && formik.errors.email ? (
          <p
            style={{
              color: "red",
              marginTop: "5px",
              fontSize: "13px",
              marginLeft: "3px",
              marginBottom: "0",
              fontFamily: "SFmedium",
              marginBottom:'8px'
            }}
          >
            {formik.errors.email}
          </p>
        ) : null}
          </div>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            type="email"
            placeholder="Enter Email"
            id="email"
          />
        </div>
        
        <div className="input-container">
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <label htmlFor="" style={{fontSize:'14px', marginBottom:'8px'}}>Password</label>
          {formik.touched.password && formik.errors.password ? (
          <p
            style={{
              color: "red",
              marginTop: "5px",
              fontSize: "13px",
              marginLeft: "3px",
              marginBottom: "0",
              fontFamily: "SFmedium",
              marginBottom:'8px'
            }}
          >
            {formik.errors.password}
          </p>
        ) : null}
          </div>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            type="password"
            placeholder="Enter Password"
            id="password"
          />
        </div>
        <div className="input-container">
        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <label htmlFor="" style={{fontSize:'14px', marginBottom:'8px'}}>Comfirm Password</label>
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
          <p
            style={{
              color: "red",
              marginTop: "5px",
              fontSize: "13px",
              marginLeft: "3px",
              marginBottom: "0",
              fontFamily: "SFmedium",
              marginBottom:'8px'
            }}
          >
            {formik.errors.confirmPassword}
          </p>
        ) : null}
          </div>
          <input
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            type="password"
            placeholder="Confirm Password"
            id="confirmPassword"
          />
        </div>

        <div className="checkbox-container">
          <label style={{marginRight:'5px', fontSize:'14px'}}>Keep me Login</label>
          <input type="checkbox" id="checkbox" />
          <label for="checkbox" class="custom-checkbox"></label>
        </div>
        {loading?
        <button type="button" disabled className="loading-button">
        Authenticating ....
      </button>
        :
        <button type="submit" className="login-button">
          Onboard Now
        </button>
        }
        
        
        {/* <span class="recover-password" >Forgot Password?</span> */}
      </form>
    </>
  );
};

export default OnboardFormContainer;
