import React, { useState, useCallback,useMemo, useEffect,useRef } from "react";
import ellipse from '../../../assets/Ellipse.png'
import discovery from '../../../assets/boxsearch.png'
import Icon_3 from '../../../assets/Icon (3).png'
import Icon_4 from '../../../assets/Icon (4).png'
import Icon_5 from '../../../assets/Icon (5).png'
import Icon_6 from '../../../assets/Icon (6).png'
import Icon_7 from '../../../assets/Icon (7).png'
import bell_1 from '../../../assets/bell (1).png'
import bell_3 from '../../../assets/bell (3).png'
import bell_4 from '../../../assets/bell (4).png'
import Icon_8 from '../../../assets/Icon (8).png'
import Icon_9 from '../../../assets/Icon (9).png'
import Icon_11 from '../../../assets/Icon@2x.png'
import edit from '../../../assets/Edit.png'
import delete_img from '../../../assets/Delete.png'
import file from '../../../assets/file.png'

import ProfileIcon from "../../../assets/profile.svg";
import LogoutIcon from "../../../assets/logout1.svg";
import search from "../../../assets/search.svg";

import { useNavigate, Link } from "react-router-dom";

import MainIcon from "../../../assets/main_logo.png";

function WorkSpace() {
    const [isVisibleProfile, setIsVisibleProfile] = useState(false);

    const toggleDropdownProfile = () => {
        setIsVisibleProfile(!isVisibleProfile);
    };

    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem("ihub_user");
        navigate("/login");
      };
    return ( 
        <>
        
        <div style={{maxWidth:'90%', margin:'0 auto', position:'relative'}}>
        
        <div className="header">
            <div className="logo">
                {/* <div></div> */}
                <Link to={'/'} style={{ width:'100%', textDecoration:'none', color:'#000000' }}>
                <img src={MainIcon} alt="logo" width={45} height={45} style={{ borderRadius:'50%' }} />
                
                </Link>
            </div>
            <div className="right-side-header">
                <div className="icons" style={{marginRight:'3rem'}}>
                <img style={{margin:'0 1rem'}} width={20} height={20} src={bell_1} alt="" />
                <img style={{margin:'0 1rem'}} width={20} height={20} src={bell_4} alt="" />
                   
                </div>
                <div className="icons" style={{ position: "relative" }} onClick={toggleDropdownProfile}>
                    <div
                    className={`dropdown-profile ${isVisibleProfile ? "visible" : "hidden"
                    }`}
                >
                    <div className="dropdown-item">
                    <span>Account</span>
                    <img src={ProfileIcon} width={20} height={20} alt="profile" />
                    </div>
                    <div className="dropdown-item" onClick={logout}>
                    <span>Log out</span>
                    <img src={LogoutIcon} width={20} height={20} alt="logout" />
                    </div>
                </div>
                    <img width={40} height={40} src={ellipse} alt="" />
                </div>
            </div>
        </div>
        <div className="work-space" >
            <h2>Work Space</h2>
        </div>
        <div className="main-overview" >
            <div className="left-menu" >
                <ul>
                    <li className='active'><img width={20} height={20} src={Icon_6} className="icon" alt=""/>Work Space Overview</li>
                    <li><img width={20} height={20} src={Icon_9} className="icon" alt=""/>Sprint Board</li>
                    <li><img width={20} height={20} src={bell_3} className="icon" alt=""/>Team Backlog</li>
                    <li><img width={20} height={20} src={file} className="icon" alt=""/>Compliance</li>
                    <li><img width={20} height={20} src={file} className="icon" alt=""/>User Experience (UX)</li>
                    <li><img width={20} height={20} src={Icon_7} className="icon" alt=""/>Leads</li>
                    <Link to={'/listing'} style={{ width:'100%', textDecoration:'none', color:'#000000' }}>
                    <li ><img width={20} height={20} src={Icon_4} className="icon" alt=""/>Listing </li>
                    </Link>
                    <li><img width={20} height={20} src={Icon_3} className="icon" alt=""/>Ouality</li>
                    <li><img width={20} height={20} src={discovery} className="icon" alt=""/>Discovery</li>
                    <li><img width={20} height={20} src={Icon_11} className="icon" alt=""/>Maintenance</li>
                    <li><img width={20} height={20} src={file} className="icon" alt=""/>Governance</li>
                    <li><img width={20} height={20} src={Icon_8} className="icon" alt=""/>Security</li>
                </ul>
            </div>
           
        </div>

        <div className="footer" style={{ background:'black', width:'100%', padding:'1rem', marginTop:'2rem' }}>
            <h2 style={{ textAlign:'center', color:'white' }}>Collaborate with Team Mates, Any Where, Any Time.</h2>
        </div>
        
        </div>
        </>
     );
}

export default WorkSpace;